import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { makeStyles } from "@mui/styles";
import NoDataMessage from './NoDataMessage';
import { ExportButton } from './StrategyContent';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  chartContainer: {
    flex: 1,
    position: 'relative',
    width: '100%',
    minHeight: '40vh',
    '@media (max-width: 600px)': {
      minHeight: '30vh',
    },
    '@media (min-width: 700px)': {
        minHeight: '28vh',
      },
    '@media (min-width: 1200px)': {
      minHeight: '28vh',
    },
  },
}));

const PerformanceChart = ({ data, options, isFullscreen }) => {
  const classes = useStyles();
  const chartRef = useRef(null);

  useEffect(() => {
    const resizeChart = () => {
      if (chartRef.current) {
        chartRef.current.resize();
      }
    };

    resizeChart();

    window.addEventListener('resize', resizeChart);

    return () => {
      window.removeEventListener('resize', resizeChart);
    };
  }, [isFullscreen]);

  if (!data || data.labels.length === 0) {
    return <NoDataMessage message="Please click calculate button on settings." />;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <ExportButton chartId="performanceChartExport" />
      </Box>
      <Box className={classes.chartContainer}>
        <div id="performanceChartExport" style={{ height: '100%', width: '100%' }}>
          <Line
            data={data}
            options={{
              ...options,
              maintainAspectRatio: false,
              responsive: true,
            }}
            ref={chartRef}
          />
        </div>
      </Box>
    </Box>
  );
};

export default PerformanceChart;