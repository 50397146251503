import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Scatter } from 'react-chartjs-2';
import { makeStyles } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';

import {
  Chart as ChartJS,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { fetchAnnualizedReturnsVolatility } from '../features/strategySlice';
import { assetTypeColors } from './strategies/utils';
import NoDataMessage from './strategies/NoDataMessage';
import { ExportButton } from './strategies/StrategyContent';

ChartJS.register(
  Title,
  ChartTooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement
);

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  chartContainer: {
    flex: 1,
    position: 'relative',
    width: '100%',
    minHeight: '300px',
  },
  canvas: {
    height: '100%',
    width: '100%',
  },
}));

const ScatterChart = ({ triggerResize, isScatterFullscreen, strategyId, assetType, annualized, perfStd, strategy }) => {
  const isLessThan1400px = useMediaQuery('(max-width:1400px)');
  const classes = useStyles({ isScatterFullscreen, isLessThan1400px });

  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const data = useSelector(state => state.strategy.annualizedReturnsVolatility) || [];
  const status = useSelector(state => state.strategy.annualizedReturnsVolatilityStatus);
  const error = useSelector(state => state.strategy.annualizedReturnsVolatilityError);
  const settings = useSelector(state => state.strategy.settings);

  useEffect(() => {
    const resizeChart = () => {
      if (chartRef.current) {
        chartRef.current.resize();
      }
    };

    resizeChart();

    window.addEventListener('resize', resizeChart);

    return () => {
      window.removeEventListener('resize', resizeChart);
    };
  }, [triggerResize, isScatterFullscreen]);

  useEffect(() => {
    if (settings.startDate && settings.endDate && strategyId) {
      const startYear = new Date(settings.startDate).getFullYear();
      const endYear = new Date(settings.endDate).getFullYear()
      dispatch(fetchAnnualizedReturnsVolatility({ id: strategyId, start_year: startYear, end_year: endYear }));
    }
  }, [dispatch, settings, strategyId]);

  const chartData = {
    datasets: [
      {
        label: 'Assets',
        data: data.map((point, index) => ({
          x: point.x,
          y: point.y,
          label: point.label,
          assetType: assetType[index] || 'Unknown'
        })),
        backgroundColor: data.map((_, index) => assetTypeColors[assetType[index]] || assetTypeColors.Unknown),
        borderColor: data.map((_, index) => assetTypeColors[assetType[index]] || assetTypeColors.Unknown),
        borderWidth: 1,
        pointRadius: 5,
        pointHoverRadius: 8,
      },
      {
        label: 'Risk and Return point',
        data: [{ x: perfStd, y: annualized }],
        backgroundColor: 'green',
        borderColor: 'green',
        borderWidth: 2,
        pointRadius: 7,
        pointHoverRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.raw.label ? context.raw.label : `${strategy?.name}`}: Volatility ${context.raw.x.toFixed(2)}%, Return ${context.raw.y.toFixed(2)}%`;
          },
        },
        titleFont: {
          size: 16
        },
        bodyFont: {
          size: 16
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: 'Volatility (%)',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
        ticks: {
          callback: function(value) {
            return value.toFixed(1) + '%';
          }
        },
        min: 0,
        max: 50,
      },
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Return (%)',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
        ticks: {
          callback: function(value) {
            return value.toFixed(1) + '%';
          },
        },
      },
    },
  };

  if (!status || !data) {
    return <NoDataMessage message="Please click calculate button on settings." />;
  }

  return (
    <Box className={classes.container}>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Error: {error}</div>}
      {status === 'succeeded' && data && data.length > 0 && (
        <>
          <Box className={classes.headerContainer}>
            <ExportButton chartId="scatterChart" />
          </Box>
          <Box className={classes.chartContainer}>
            <div id="scatterChart" className={classes.canvas}>
              <Scatter ref={chartRef} data={chartData} options={options} />
            </div>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ScatterChart;