import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
  const { value } = props;
  const isOverLimit = value > 100;
  const displayValue = isOverLimit ? 100 : value;
  const color = isOverLimit ? 'error' : 'primary';
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', backgroundColor: '#fff', borderRadius: '50%' }}>
      <CircularProgress variant="determinate" value={displayValue} color={color} size={60} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;