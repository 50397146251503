import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Tooltip,
  Divider,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import DrawDown from "./DrawDown";
import ScatterChart from "../ScatterChart";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  setStrategyData,
  openStrategyDrawer,
  openInfoDrawer,
} from "../../features/drawerSlice";
import {
  fetchAnnualizedReturnsVolatility,
  fetchDrawdownData,
  fetchNominalPerformanceData,
  fetchPerformanceData,
} from "../../features/strategySlice";
import { assetTypeColors } from "./utils";
import InfoDrawer from "../InfoDrawer";
import Cladogram from "./Cladogram";
import MonteCarloChart from "./MonteCarloChart";
import theme from "../../theme";
import { isEqual } from "lodash";
import NoDataMessage from "./NoDataMessage";
import DownloadIcon from "@mui/icons-material/Download";
import html2canvas from "html2canvas";
import PerformanceChart from "./PerformanceChart";

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: "35vh",
    transition: "all 0.3s ease",
  },
  scatterCladogram: {
    height: "50vh",
  },
  fullscreenChart: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 9999,
    backgroundColor: "white",
    padding: 10,
    overflow: "auto",
  },
  doughnutFull: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100% - 100px)",
  },
}));

export const ExportButton = ({ chartId }) => {
  const handleExport = () => {
    const chartElement = document.getElementById(chartId);
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const link = document.createElement("a");
        link.download = `${chartId}.png`;
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  };

  return (
    <Tooltip title="Export as image" enterTouchDelay={0}>
      <IconButton onClick={handleExport}>
        <DownloadIcon sx={{ color: "#1976d2" }} />
      </IconButton>
    </Tooltip>
  );
};

const StrategyContent = ({
  strategy,
  zoomOptions,
  strategyOverviewData,
  performanceData,
  drawdownData,
  yearRange,
  assets,
  strategyId,
  annualizedReturns,
  std,
}) => {
  const doughnutRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [resizeTrigger, setResizeTrigger] = useState(0);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPerformanceFullscreen, setIsPerformanceFullscreen] = useState(false);
  const [isDrawdownFullscreen, setIsDrawdownFullscreen] = useState(false);
  const [isScatterFullscreen, setIsScatterFullscreen] = useState(false);
  const [isCladogramFullscreen, setIsCladogramFullscreen] = useState(false);
  const [isMonteCarloFullscreen, setIsMonteCarloFullscreen] = useState(false);
  const [selectedSection, setSelectedSection] = useState("Select page section");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [meanReturn, setMeanReturn] = useState(0);
  const [volatility, setVolatility] = useState(0);
  const settings = useSelector((state) => state.strategy.settings);
  const nominalPerformanceData = useSelector(
    (state) => state.strategy.nominalPerformanceData
  );

  const dataLoaded = useSelector((state) => state.strategy.dataLoaded);
  const lastLoadedParams = useSelector(
    (state) => state.strategy.lastLoadedParams
  );

  const [strategyPerformanceData, setStrategyPerformanceData] = useState({
    labels: [],
    datasets: [],
  });

  const zoomOptionsPerf = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += "£" + context.parsed.y.toFixed(2);
            }
            return label;
          },
        },
      },
      zoom: {
        zoom: {
          wheel: { enabled: true },
          pinch: { enabled: true },
          mode: "xy",
        },
        pan: {
          enabled: true,
          mode: "xy",
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Years",
          font: {
            size: 16,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Performance £",
          font: {
            size: 16,
          },
        },
        min: 0,
      },
    },
  };

  const handleEditComposition = () => {
    dispatch(setStrategyData({ ...strategy }));
    dispatch(openStrategyDrawer());
  };

  const handleOpenDrawer = (content) => {
    dispatch(openInfoDrawer(content));
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (strategy && yearRange && yearRange.length === 2) {
      const params = {
        id: strategyId,
        start_year: yearRange[0],
        end_year: yearRange[1],
        rebalancing_frequency_in_years: settings.rebalancingPeriod,
        investment_amount: parseFloat(settings.investmentAmount),
      };

      if (settings.transactionFeeType !== "-" && settings.transactionFeeValue) {
        let adjustedTransactionFeeAmount = parseFloat(
          settings.transactionFeeValue
        );
        const isPercentage =
          settings.transactionFeeType === "percentage" ||
          settings.transactionFeeType === "%";

        if (isPercentage) {
          adjustedTransactionFeeAmount = adjustedTransactionFeeAmount / 100;
        }

        params.transaction_fee_amount = adjustedTransactionFeeAmount;
        params.transaction_fee_type = isPercentage ? "percentage" : "pounds";
      }

      if (!dataLoaded || !isEqual(params, lastLoadedParams)) {
        dispatch(fetchNominalPerformanceData(params));
        dispatch(fetchPerformanceData(params));
        dispatch(fetchDrawdownData(params));
        dispatch(fetchAnnualizedReturnsVolatility(params));
      }
    }
  }, [
    dispatch,
    strategy,
    yearRange,
    settings,
    strategyId,
    dataLoaded,
    lastLoadedParams,
  ]);

  useEffect(() => {
    if (performanceData?.performance_data) {
      const chartData = {
        labels: performanceData.performance_data.map((item) => item.year),
        datasets: [
          {
            label: "Strategy Performance",
            data: performanceData.performance_data.map(
              (item) => item.performance
            ),
            borderColor: "rgba(75,192,192,1)",
            backgroundColor: "rgba(75,192,192,0.4)",
          },
        ],
      };
      setStrategyPerformanceData(chartData);
    }
  }, [performanceData, yearRange]);

  useEffect(() => {
    if (nominalPerformanceData && nominalPerformanceData.performance) {
      const formattedMeanReturn = parseFloat(
        nominalPerformanceData.performance.annualized_returns.toFixed(2)
      );
      const formattedVolatility = parseFloat(
        nominalPerformanceData.performance.std.toFixed(2)
      );
      setMeanReturn(formattedMeanReturn);
      setVolatility(formattedVolatility);
    }
  }, [nominalPerformanceData]);

  const onHandleFullPerformance = () => {
    setIsPerformanceFullscreen((prev) => !prev);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
      setResizeTrigger((prev) => prev + 1);
    }, 100);
  };

  const onHandleFullDrawdown = () => {
    setIsDrawdownFullscreen((prev) => !prev);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
      setResizeTrigger((prev) => prev + 1);
    }, 100);
  };

  const onHandleFullScatter = () => {
    setIsScatterFullscreen((prev) => !prev);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
      setResizeTrigger((prev) => prev + 1);
    }, 100);
  };

  const onHandleFullCladogram = () => {
    setIsCladogramFullscreen((prev) => !prev);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
      setResizeTrigger((prev) => prev + 1);
    }, 100);
  };

  const onHandleFullMonteCarlo = () => {
    setIsMonteCarloFullscreen((prev) => !prev);
    setTimeout(() => {
      document
        .getElementById("monte-carlo")
        .scrollIntoView({ behavior: "auto" });
      window.dispatchEvent(new Event("resize"));
      setResizeTrigger((prev) => prev + 1);
    }, 100);
  };

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "white",
      color: "black",
      fontSize: 14,
      border: "1px solid #dadde9",
    },
  }));

  useEffect(() => {
    const handleResize = () => {
      setResizeTrigger((prev) => prev + 1);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSectionChange = (event) => {
    const sectionId = event.target.value;
    setSelectedSection(sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const assetTypes = strategy?.assets?.map((asset) => {
    const assetData = assets.find((a) => a.ticker === asset.asset_ticker) || {};
    return assetData.asset_type || "Unknown";
  });

  const ann =
    performanceData?.annualized_returns !== undefined
      ? performanceData.annualized_returns * 100
      : null;
  const perstd =
    performanceData?.std !== undefined ? performanceData.std * 100 : null;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert(`Copied: ${text}`);
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  return (
    <Grid container sx={{ background: "#eff0f6" }}>
      {isMobile && (
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={3}>
              <Typography
                style={{
                  fontFamily: "Montserrat , sans serif",
                  fontSize: "16px",
                  color: "#1D2155",
                  fontWeight: "500",
                }}
              >
                Jump to:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth variant="outlined">
                <Select
                  value={selectedSection}
                  onChange={handleSectionChange}
                  inputProps={{
                    name: "section",
                    id: "section-select",
                  }}
                >
                  <MenuItem value="Select page section" disabled>
                    Select a section
                  </MenuItem>
                  <MenuItem value="overview">Overview</MenuItem>
                  <MenuItem value="performance">Performance</MenuItem>
                  <MenuItem value="drawdown">Downside Risk</MenuItem>
                  <MenuItem value="risk-return">Risk & Return</MenuItem>
                  <MenuItem value="cladogram">Cladogram</MenuItem>
                  <MenuItem value="monte-carlo">
                    Monte Carlo Simulation
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* Overview Section */}
      <Grid item xs={12} id="overview">
        <Paper
          elevation={1}
          style={{
            padding: "16px",
            position: "relative",
            textAlign: isMobile ? "center" : "unset",
          }}
          ref={doughnutRef}
        >
          <Box sx={{ display: "inline-block" }}>
            <Typography
              variant="body3"
              style={{ fontSize: "30px", color: "#1D2155", fontWeight: 600 }}
              gutterBottom
            >
              Overview
            </Typography>
            <Divider
              sx={{
                borderBottomWidth: 6,
                width: "100px",
                borderColor: "#00D0AF",
                marginTop: "1px",
                opacity: 1,
              }}
            />
          </Box>

          <Grid container justifyContent="end">
            <ExportButton chartId="strategyOverviewChart" />
          </Grid>
          <Grid
            item
            xs={9}
            lg={4}
            margin="auto"
            id="strategyOverviewChart"
            className={clsx(isFullscreen && classes.doughnutFull)}
          >
            <Doughnut data={strategyOverviewData} options={zoomOptions} />
          </Grid>

          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ borderBottom: "3px solid #00D0AF" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: 600 }}>Fund</TableCell>
                  {!isMobile && (
                    <TableCell style={{ fontWeight: 600 }}>
                      Asset Type
                    </TableCell>
                  )}
                  {!isMobile && (
                    <TableCell style={{ fontWeight: 600 }}>Ticker</TableCell>
                  )}
                  <TableCell style={{ fontWeight: 600 }} align="right">
                    Allocation
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {strategy?.assets?.map((asset, index) => {
                  const assetData =
                    assets.find((a) => a.ticker === asset.asset_ticker) || {};
                  const assetType = assetData.asset_type || "Unknown";
                  const isin = assetData.isin || "N/A";
                  const assetColor =
                    assetTypeColors[assetType] || assetTypeColors.Unknown;

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          fontWeight={600}
                        >
                          <Box
                            width={10}
                            height={10}
                            borderRadius="50%"
                            bgcolor={assetColor}
                            mr={1}
                          />
                          {asset.asset_description}
                          <Box ml={1}>
                            <CustomTooltip
                              title={
                                <Box>
                                  <Typography
                                    variant="body2"
                                    component="strong"
                                  >
                                    Corresponding UK fund:
                                  </Typography>
                                  <Typography variant="body2">
                                    {assetData.uk_description ||
                                      "No UK description available"}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    component="strong"
                                    sx={{
                                      cursor: "pointer",
                                      color: "#1976d2",
                                    }}
                                    onClick={() => copyToClipboard(isin)}
                                  >
                                    copy ISIN
                                  </Typography>
                                </Box>
                              }
                            >
                              <Box display="inline-block" padding="5px">
                                <InfoOutlinedIcon
                                  color="primary"
                                  fontSize="medium"
                                />
                              </Box>
                            </CustomTooltip>
                          </Box>
                        </Box>
                        {isMobile && (
                          <Box mt={1}>
                            <Typography variant="body2">
                              <strong>Asset Type:</strong> {assetType}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Ticker:</strong>{" "}
                              {asset.asset_ticker || "N/A"}
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                      {!isMobile && <TableCell>{assetType}</TableCell>}
                      {!isMobile && (
                        <TableCell>{asset.asset_ticker || "N/A"}</TableCell>
                      )}
                      <TableCell align="right">{asset.weight * 100}%</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box textAlign={isMobile ? "left" : "right"} mt={2}>
            <Button
              variant="text"
              sx={{ color: "#008ED1", textTransform: " capitalize" }}
              onClick={handleEditComposition}
            >
              <span style={{ marginRight: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                  id="Hand-Held-Tablet-Writing--Streamline-Core-Remix"
                  height="14"
                  width="14"
                >
                  <desc>
                    Hand Held Tablet Writing Streamline Icon:
                    https://streamlinehq.com
                  </desc>
                  <g id="Free Remix/Computer Devices/hand-held-tablet-writing--tablet-kindle-device-electronics-ipad-writing-digital-paper-notepad">
                    <path
                      id="Union"
                      fill="#008ED1"
                      fillRule="evenodd"
                      d="M12.0133 0.146447c-0.0938 -0.0938331 -0.2211 -0.1465169062 -0.3538 -0.1464469304C11.5268 0.0000711184 11.3996 0.0528901 11.3058 0.146823L6.60583 4.85682c-0.07653 0.07669 -0.12608 0.17616 -0.14119 0.28345l-0.3 2.13c-0.02184 0.15509 0.03025 0.31146 0.14075 0.42247 0.1105 0.11101 0.26662 0.16382 0.42182 0.14269l2.13 -0.29c0.10831 -0.01475 0.20881 -0.06458 0.2861 -0.14188l4.70999 -4.71c0.1953 -0.19526 0.1953 -0.51184 0 -0.7071l-1.84 -1.840003ZM1.27637 1.625c0 -0.20711 0.16789 -0.375 0.375 -0.375h6.875c0.34517 0 0.625 -0.279822 0.625 -0.625S8.87154 6.96e-8 8.52637 6.96e-8h-6.875C0.753904 6.96e-8 0.0263672 0.727537 0.0263672 1.625v10.75c0 0.8975 0.7275368 1.625 1.6250028 1.625h9.00003c0.8974 0 1.625 -0.7275 1.625 -1.625v-4.75c0 -0.34518 -0.2799 -0.625 -0.625 -0.625 -0.3452 0 -0.625 0.27982 -0.625 0.625v2.8828H1.27637V1.625ZM3.02637 3c0 -0.34518 0.27982 -0.625 0.625 -0.625h2c0.34518 0 0.625 0.27982 0.625 0.625s-0.27982 0.625 -0.625 0.625h-2c-0.34518 0 -0.625 -0.27982 -0.625 -0.625Zm0.625 1.875c-0.34518 0 -0.625 0.27982 -0.625 0.625s0.27982 0.625 0.625 0.625h1c0.34518 0 0.625 -0.27982 0.625 -0.625s-0.27982 -0.625 -0.625 -0.625h-1Z"
                      clipRule="evenodd"
                      strokeWidth="1"
                    ></path>
                  </g>
                </svg>
              </span>
              Edit Composition
            </Button>
          </Box>
        </Paper>
      </Grid>

      {/* Performance Metrics */}
      <Grid
        item
        xs={12}
        gap={2}
        mt={!isMobile ? 2 : 0}
        flexDirection={isMobile ? "column" : "row"}
        sx={{ display: !isMobile ? "flex" : "block" }}
      >
        <Grid item lg={12} flex={1} mb={isMobile ? 2 : 0} mt={isMobile ? 2 : 0}>
          <Paper elevation={3}>
            {performanceData?.annualized_returns !== undefined ? (
              <Box p={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "#1D2155", fontWeight: 600 }}
                  >
                    Compound real annual growth rate
                  </Typography>
                  <Tooltip
                    title="This is the rate of return that your portfolio would have generated per year based on historical returns and your chosen rebalancing frequency.
               This is a real total return i.e. any income is reinvested and the return is adjusted for inflation. A positive real return means that you have beaten inflation.
               "
                    arrow
                    enterTouchDelay={0}
                  >
                    <InfoOutlinedIcon color="primary" fontSize="medium" />
                  </Tooltip>
                </Box>
                <Grid
                  container
                  spacing={2}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body2"
                      style={{
                        color: "#1D2155",
                        fontSize: 45,
                        fontWeight: 700,
                      }}
                    >
                      {performanceData?.annualized_returns !== undefined
                        ? `${(performanceData.annualized_returns * 100).toFixed(
                            2
                          )}%`
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body2"
                      style={{
                        color: "#1D2155",
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      Before inflation <b>{(meanReturn * 100).toFixed(2)}%</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <NoDataMessage message="Please click calculate button on settings." />
            )}
          </Paper>
        </Grid>
        <Grid item lg={12} flex={1}>
          <Paper elevation={3}>
            {performanceData?.std !== undefined ? (
              <Box p={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "#1D2155", fontWeight: 600 }}
                  >
                    Volatility
                  </Typography>
                  <Tooltip
                    title="Volatility, or standard deviation of returns, is the typical annual percentage price movement of your portfolio either up or down. This helps you gauge the likelihood of large returns with lower volatility seen as less risky than higher volatility as it makes large losses less likely. A very rough approximation would be that a loss exceeding one standard deviation occurs in 16% of years;
                  a loss of two standard deviations occurs in 2% of years and a loss of three standard deviations occurs in 0.1% of years."
                    arrow
                    enterTouchDelay={0}
                  >
                    <InfoOutlinedIcon color="primary" fontSize="medium" />
                  </Tooltip>
                </Box>
                <Grid
                  container
                  spacing={2}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body2"
                      style={{
                        color: "#1D2155",
                        fontSize: 45,
                        fontWeight: 700,
                      }}
                    >
                      {performanceData?.std !== undefined
                        ? `${(performanceData.std * 100).toFixed(2)}%`
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body2"
                      style={{
                        color: "#1D2155",
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      Before inflation <b>{(volatility * 100).toFixed(2)}%</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <NoDataMessage message="Please click calculate button on settings." />
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Performance Chart */}
      <Grid
        // container
        item
        xs={12}
        gap={2}
        style={{
          paddingTop: "25px",
          flexDirection:
            isPerformanceFullscreen || isDrawdownFullscreen || isMobile
              ? "column"
              : "row",
          display: "flex",
        }}
      >
        {!isDrawdownFullscreen && (
          <Grid
            item
            id="performance"
            className={isPerformanceFullscreen ? classes.fullscreenChart : ""}
            style={{
              flex: isPerformanceFullscreen
                ? "1 0 100%"
                : isMobile
                ? ""
                : "1 0 calc(50% - 8px)",
              maxWidth: isPerformanceFullscreen
                ? "100%"
                : isMobile
                ? ""
                : "calc(50% - 8px)",
            }}
          >
            <Box
              sx={{
                paddingLeft: isMobile ? "0px" : "8px",
                display: isMobile ? "block" : "inline-block",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Typography
                variant="body3"
                style={{ fontSize: "30px", color: "#1D2155", fontWeight: 600 }}
                gutterBottom
              >
                Strategy Performance
              </Typography>
              <Divider
                sx={{
                  borderBottomWidth: 6,
                  width: "100px",
                  borderColor: "#00D0AF",
                  marginTop: "3px",
                  marginBottom: "8px",
                  marginLeft: isMobile ? "140px" : "0",
                  opacity: 1,
                }}
              />
            </Box>
            <Paper
              elevation={1}
              className={clsx(
                classes.chartContainer,
                isPerformanceFullscreen && classes.fullscreenChart
              )}
            >
              <div
                id="performanceChart"
                style={{ width: "100%", height: "100%" }}
              >
                {isPerformanceFullscreen && (
                  <IconButton
                    onClick={onHandleFullPerformance}
                    style={{
                      background: "#1976d2",
                      position: "absolute",
                      borderRadius: "0px",
                      padding: "4px",
                      top: 19,
                      right: 60,
                      zIndex: 10000,
                    }}
                  >
                    <CloseFullscreenIcon
                      sx={{ color: "#fff" }}
                      fontSize="medium"
                    />
                  </IconButton>
                )}
                <PerformanceChart
                  data={strategyPerformanceData}
                  options={zoomOptionsPerf}
                  isFullscreen={isPerformanceFullscreen}
                />
              </div>
            </Paper>
            <Paper elevation={1} style={{ padding: "0px" }}>
              <Grid container pl={isMobile ? 1 : 0} alignItems="center">
                <Grid item xs={8} md={11}>
                  <Typography
                    variant="body3"
                    style={{ fontSize: "17px", color: "#008ED1" }}
                    gutterBottom
                  >
                    <Button
                      variant="text"
                      style={{
                        textDecoration: "underline",
                        padding: isMobile ? "2px" : "2px 14px",
                      }}
                      onClick={() => handleOpenDrawer("performance")}
                    >
                      Learn More
                    </Button>
                    how we calculate the performance
                  </Typography>
                </Grid>
                {!isMobile && (
                  <Grid item xs={4} md={1}>
                    <IconButton
                      onClick={onHandleFullPerformance}
                      sx={{
                        float: "right",
                        background: "#1976d2",
                        padding: "14px",
                        borderRadius: 0,
                        "&:hover": {
                          background: "#1976d2",
                        },
                      }}
                    >
                      <OpenInFullIcon
                        sx={{ color: "#fff" }}
                        fontSize="medium"
                      />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        )}

        {/* Drawdown Section */}
        <Grid
          item
          id="drawdown"
          className={isDrawdownFullscreen ? classes.fullscreenChart : ""}
          style={{
            flex: isDrawdownFullscreen
              ? "1 0 100%"
              : isMobile
              ? ""
              : "1 0 calc(50% - 8px)",
            maxWidth: isDrawdownFullscreen
              ? "100%"
              : isMobile
              ? ""
              : "calc(50% - 8px)",
          }}
        >
          <Box
            sx={{
              paddingLeft: isMobile ? "0px" : "8px",
              display: isMobile ? "block" : "inline-block",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <Typography
              variant="body3"
              style={{ fontSize: "30px", color: "#1D2155", fontWeight: 600 }}
              gutterBottom
            >
              Downside Risk
            </Typography>
            <Divider
              sx={{
                borderBottomWidth: 6,
                width: "100px",
                borderColor: "#00D0AF",
                marginTop: "3px",
                marginBottom: "8px",
                marginLeft: isMobile ? "140px" : "0px",
                opacity: 1,
              }}
            />
          </Box>
          <Paper
            elevation={1}
            className={clsx(
              classes.chartContainer,
              isDrawdownFullscreen && classes.fullscreenChart
            )}
          >
            <div style={{ width: "100%", height: "100%" }}>
              {isDrawdownFullscreen && (
                <IconButton
                  onClick={onHandleFullDrawdown}
                  style={{
                    background: "#1976d2",
                    position: "absolute",
                    borderRadius: "0px",
                    padding: "4px",
                    top: 19,
                    right: 60,
                    zIndex: 10000,
                  }}
                >
                  <CloseFullscreenIcon
                    sx={{ color: "#fff" }}
                    fontSize="medium"
                  />
                </IconButton>
              )}

              <DrawDown data={drawdownData} isFullscreen={isDrawdownFullscreen} />
            </div>
          </Paper>
          <Paper elevation={1} style={{ padding: "0px" }}>
            <Grid container pl={isMobile ? 1 : 0} alignItems="center">
              <Grid item xs={8} md={11} lg={11}>
                <Typography
                  variant="body3"
                  style={{ fontSize: "17px", color: "#008ED1" }}
                  gutterBottom
                >
                  <Button
                    variant="text"
                    style={{
                      textDecoration: "underline",
                      padding: isMobile ? "2px" : "2px 14px",
                    }}
                    onClick={() => handleOpenDrawer("drawdown")}
                  >
                    Learn More
                  </Button>{" "}
                  how we calculate the Downside Risk
                </Typography>
              </Grid>
              {!isMobile && (
                <Grid item xs={4} md={1} lg={1}>
                  <IconButton
                    onClick={onHandleFullDrawdown}
                    sx={{
                      float: "right",
                      background: "#1976d2",
                      padding: "14px",
                      borderRadius: 0,
                      "&:hover": {
                        background: "#1976d2",
                      },
                    }}
                  >
                    <OpenInFullIcon sx={{ color: "#fff" }} fontSize="medium" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Risk & Return Section */}
      <Grid
        item
        xs={12}
        mt={4}
        id="risk-return"
        className={isScatterFullscreen ? classes.fullscreenChart : ""}
      >
        <Box
          sx={{
            paddingLeft: isMobile ? "0px" : "8px",
            display: isMobile ? "block" : "inline-block",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Typography
            variant="body3"
            style={{ fontSize: "30px", color: "#1D2155", fontWeight: 600 }}
            gutterBottom
          >
            Risk & Return Tool
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: 6,
              width: "100px",
              borderColor: "#00D0AF",
              marginTop: "3px",
              marginBottom: "8px",
              marginLeft: isMobile ? "140px" : "0",
              opacity: 1,
            }}
          />
        </Box>
        <Paper
          elevation={1}
          className={clsx(
            classes.scatterCladogram,
            isScatterFullscreen && classes.fullscreenChart
          )}
        >
          {isScatterFullscreen && (
            <IconButton
              onClick={onHandleFullScatter}
              style={{
                background: "#1976d2",
                position: "absolute",
                padding: "4px",
                top: 19,
                borderRadius: "0px",
                right: 60,
                zIndex: 10000,
              }}
            >
              <CloseFullscreenIcon sx={{ color: "#fff" }} fontSize="medium" />
            </IconButton>
          )}
          <ScatterChart
            triggerResize={resizeTrigger}
            strategy={strategy}
            strategyId={strategyId}
            isScatterFullscreen={isScatterFullscreen}
            assetType={assetTypes}
            annualized={ann}
            perfStd={perstd}
          />
        </Paper>
        <Paper elevation={1} style={{ padding: 0 }}>
          <Grid container pl={isMobile ? 1 : 0} alignItems="center">
            <Grid item xs={8}>
              <Typography
                variant="body3"
                style={{ fontSize: "17px", color: "#008ED1" }}
                gutterBottom
              >
                <Button
                  variant="text"
                  style={{
                    textDecoration: "underline",
                    padding: isMobile ? "2px" : "14px",
                  }}
                  onClick={() => handleOpenDrawer("Risk & Return")}
                >
                  Learn More
                </Button>
                how we calculate the Risk & Return
              </Typography>
            </Grid>
            {!isMobile && (
              <Grid item xs={4}>
                <IconButton
                  onClick={onHandleFullScatter}
                  sx={{
                    float: "right",
                    background: "#1976d2",
                    borderRadius: 0,
                    padding: "14px",
                    "&:hover": {
                      background: "#1976d2",
                    },
                  }}
                >
                  <OpenInFullIcon sx={{ color: "#fff" }} fontSize="medium" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>

      {/* cladogram */}
      <Grid
        item
        xs={12}
        mt={4}
        id="cladogram"
        className={isCladogramFullscreen ? classes.fullscreenChart : ""}
      >
        <Box
          sx={{
            paddingLeft: isMobile ? "0px" : "8px",
            display: isMobile ? "block" : "inline-block",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Typography
            variant="body3"
            style={{ fontSize: "30px", color: "#1D2155", fontWeight: 600 }}
            gutterBottom
          >
            Cladogram Tool
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: 6,
              width: "100px",
              borderColor: "#00D0AF",
              marginTop: "3px",
              marginBottom: "8px",
              marginLeft: isMobile ? "140px" : "0px",
              opacity: 1,
            }}
          />
        </Box>
        <Paper
          elevation={1}
          style={{ padding: "16px" }}
          className={clsx(
            classes.scatterCladogram,
            isCladogramFullscreen && classes.fullscreenChart
          )}
        >
          {isCladogramFullscreen && (
            <IconButton
              onClick={onHandleFullCladogram}
              style={{
                background: "#1976d2",
                position: "absolute",
                padding: "4px",
                top: 19,
                right: 60,
                borderRadius: "0px",
                zIndex: 10000,
              }}
            >
              <CloseFullscreenIcon sx={{ color: "#fff" }} fontSize="medium" />
            </IconButton>
          )}
          <div id="treeWrapper" style={{ width: "100%", height: "100%" }}>
            <Cladogram
              // data={backendData}
              strategyId={strategyId}
            />
          </div>
        </Paper>
        <Paper elevation={1} style={{ padding: 0 }}>
          <Grid container pl={isMobile ? 1 : 0} alignItems="center">
            <Grid item xs={8}>
              <Typography
                variant="body3"
                style={{ fontSize: "17px", color: "#008ED1" }}
                gutterBottom
              >
                <span>
                  <Button
                    variant="text"
                    style={{
                      textDecoration: "underline",
                      padding: isMobile ? "2px" : "14px",
                    }}
                    onClick={() => handleOpenDrawer("cladogram")}
                  >
                    Learn More
                  </Button>
                </span>
                how we calculate the cladogram
              </Typography>
            </Grid>
            {!isMobile && (
              <Grid item xs={4}>
                <IconButton
                  onClick={onHandleFullCladogram}
                  sx={{
                    float: "right",
                    background: "#1976d2",
                    borderRadius: "0px",
                    padding: "14px",
                    "&:hover": {
                      background: "#1976d2",
                    },
                  }}
                >
                  <OpenInFullIcon sx={{ color: "#fff" }} fontSize="medium" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>

      {/* monte carlo */}
      <Grid
        item
        xs={12}
        mt={4}
        id="monte-carlo"
        className={isMonteCarloFullscreen ? classes.fullscreenChart : ""}
      >
        <Box
          sx={{
            paddingLeft: isMobile ? "0px" : "8px",
            display: isMobile ? "block" : "inline-block",
            textAlign: isMobile ? "center" : "left",
            paddingBottom: "10px",
          }}
        >
          <Typography
            variant="body3"
            style={{ fontSize: "30px", color: "#1D2155", fontWeight: 600 }}
            gutterBottom
          >
            Monte Carlo Simulation
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: 6,
              width: "100px",
              borderColor: "#00D0AF",
              marginTop: "3px",
              marginBottom: "8px",
              marginLeft: isMobile ? "140px" : "0px",
              opacity: 1,
            }}
          />
        </Box>
        {isMonteCarloFullscreen && (
          <IconButton
            onClick={onHandleFullMonteCarlo}
            style={{
              background: "#1976d2",
              position: "absolute",
              padding: "4px",
              top: 19,
              right: 60,
              borderRadius: "0px",
              zIndex: 10000,
            }}
          >
            <CloseFullscreenIcon sx={{ color: "#fff" }} fontSize="medium" />
          </IconButton>
        )}
        <Paper elevation={1} className="monte-carlo-paper">
          <MonteCarloChart
            performanceData={performanceData}
            meanReturn={meanReturn}
            volatility={volatility}
          />
        </Paper>
        <Paper elevation={1} style={{ padding: 0 }}>
          <Grid container pl={isMobile ? 1 : 0} alignItems="center">
            <Grid item xs={8}>
              <Typography
                variant="body3"
                style={{ fontSize: "17px", color: "#008ED1" }}
                gutterBottom
              >
                <span>
                  <Button
                    variant="text"
                    style={{
                      textDecoration: "underline",
                      padding: isMobile ? "2px" : "14px",
                    }}
                    onClick={() => handleOpenDrawer("monteCarlo")}
                  >
                    Learn More
                  </Button>
                </span>
                how we calculate the Monte Carlo Simulation
              </Typography>
            </Grid>
            {!isMobile && (
              <Grid item xs={4}>
                <IconButton
                  onClick={onHandleFullMonteCarlo}
                  sx={{
                    float: "right",
                    background: "#1976d2",
                    borderRadius: "0px",
                    padding: "14px",
                    "&:hover": {
                      background: "#1976d2",
                    },
                  }}
                >
                  <OpenInFullIcon sx={{ color: "#fff" }} fontSize="medium" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Paper>
        <Grid container justifyContent="center" p={2} sx={{ width: "100%" }}>
          <Grid item xs={12} sm={6} md={12}>
            <Typography
              variant="body2"
              align={isMobile ? "center" : "left"}
              color="text.secondary"
            >
              Copyright © PensionCraft Ltd. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <InfoDrawer />
    </Grid>
  );
};

export default StrategyContent;
