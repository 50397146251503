import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    head: {
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '36px',
      fontWeight: 600,
      color: '#000000'
    },
    h3: {
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '1.875rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    learnDescription: {
      fontSize: '18px',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      lineHeight: '26px',
      color: '#1D2155',
    },
    sidebar: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1rem',
      fontWeight: 500,
      color: '#1D2155'
    },
    homeDescription: {
      fontSize: '18px',
      fontFamily: 'Montserrat, sans-serif',
      lineHeight: '26px',
      color: '#1D2155',
    },
    body1: {
      fontSize: '18px',
    },
    body2: {
      fontSize: '0.875rem',
    },
    body3: {
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '30px',
      fontWeight: 500
    },
    homeHead: {
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '30px',
      fontWeight: 500
    },
    subtitle1: {
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '22px'
    },
  },
});

export default theme;