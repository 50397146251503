import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Strategies from './components/strategies/Strategies';
import StrategyDetails from './components/strategies/StrategyDetails';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Button } from '@mui/material';
import store from './store/store';
import Learn from './components/Learn';
import { fetchAssets, fetchStrategies, validateToken } from './features/strategySlice';
import CreateStrategyDrawer from './components/strategies/CreateStrategyDrawer ';
import theme from './theme';
import { clearDrawer, closeStrategyDrawer, openStrategyDrawer } from './features/drawerSlice';
import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import '@fontsource/quicksand';
import '@fontsource/quicksand/500.css';
import logo from "../src/images/pensioncraft-logo.png";
import MobileMenu from './components/MobileMenu';
import ReactGA from 'react-ga4';
import { name } from './axiosConfig';

ReactGA.initialize("G-YH40LFKD39");


function App() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const strategies = useSelector((state) => state.strategy.strategies);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);


  const onToggleMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    setMobileMenuOpen(false);
  };

  const onCreateStrategy = () => {
    dispatch(clearDrawer());
    dispatch(openStrategyDrawer());
  };

  useEffect(() => {
    dispatch(fetchStrategies());
  }, [dispatch]);

  useEffect(() => {
    const initializeApp = async () => {
      await Promise.all([
        dispatch(fetchStrategies()),
        dispatch(fetchAssets())
      ]);
    };

    initializeApp();
  }, [dispatch]);

  useEffect(() => {
    dispatch(validateToken());
  }, [dispatch]);

  useEffect(() => {
    const initializeApp = async () => {
      await dispatch(validateToken());

      const updatedIsAuthenticated = store.getState().strategy.isAuthenticated;

      if (updatedIsAuthenticated) {
        await Promise.all([
          dispatch(fetchStrategies()),
          dispatch(fetchAssets())
        ]);
      } else {
        console.error('Invalid token redirect app');
        window.location.href = 'https://pensioncraft.com/investor-education/login/';
      }
    };

    initializeApp();
  }, [dispatch]);



  const handleClick = () => {
    window.location.href = 'https://pensioncraft.com/';
  };


  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div>
          <Grid container style={{ background: '#1D2155' }} justifyContent='space-between' alignItems="center">
            <Grid item>
              <Button variant="text" onClick={handleClick} style={{ color: 'white', justifyContent: 'flex-start' }}>
                <ArrowBackIosNewOutlinedIcon />
                Back to Dashboard
              </Button>
            </Grid>
            <Grid item pr={2}>
              <Typography variant="h6" style={{ color: 'white' }}> Welcome&nbsp;<b>{name || "User"}</b></Typography>
            </Grid>
          </Grid>
          {isMobile && (
            <>
              <Grid container justifyContent="space-between" p={1}>
                <Grid item xs={4} p={1}>
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "150px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Button onClick={onToggleMenu}>
                    {isMobileMenuOpen ? <CloseIcon /> : <MenuOutlinedIcon />}
                  </Button>
                </Grid>
              </Grid>
              {isMobileMenuOpen && (
                <MobileMenu
                  isMobileMenuOpen={isMobileMenuOpen}
                  onToggleMenu={onToggleMenu}
                  handleMenuItemClick={handleMenuItemClick}
                  onCreateStrategy={onCreateStrategy}
                  strategies={strategies}
                />
              )}
            </>
          )}
          <div className="d-flex">

            {!isMobile && (<Sidebar />)}
            <div className="content" style={{ flex: 1, width: '1600px' }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/strategies" element={<Strategies />} />
                <Route path="/famous" element={<Learn />} />
                <Route path="/strategy/:id" element={<StrategyDetails />} />
              </Routes>
              <CreateStrategyDrawer open={isDrawerOpen} onClose={() => dispatch(closeStrategyDrawer())} />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
