// src/components/MobileMenu.js
import React from 'react';
import featureBg from "../../src/images/feature-investment.png";
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';


const MobileMenu = ({
  isMobileMenuOpen,
  onToggleMenu,
  handleMenuItemClick,
  onCreateStrategy,
  strategies,
  widthStyle
}) => (
  <Paper style={{ height: '100vh', width: widthStyle || 'unset' }}>
    <Divider />
    <Grid container justifyContent="center" flexDirection="column" mt={4} mb={3}>
      <Grid item textAlign="center">
        <Button onClick={() => handleMenuItemClick('/')}>
          <span className="me-2">
          <span className="me-2">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.75 21.3749H22.25C22.85 21.3749 23.25 20.9749 23.25 20.3749V4.37494C23.25 3.77494 22.85 3.37494 22.25 3.37494H1.75C1.15 3.37494 0.75 3.77494 0.75 4.37494V20.3749C0.75 20.8749 1.25 21.3749 1.75 21.3749Z"
                stroke="#1D2155"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.25 3.37494V21.3749"
                stroke="#1D2155"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.54999 7.07489H3.54999"
                stroke="#1D2155"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.54999 10.3749H3.54999"
                stroke="#1D2155"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.54999 13.575H3.54999"
                stroke="#1D2155"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.55 16.7748H5.25"
                stroke="#1D2155"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          </span>
          <Typography sx={{  fontSize: '20px', fontWeight: 500, color: '#1D2155',textTransform: 'capitalize' }}>Home</Typography>
        </Button>
      </Grid>
      <Grid item textAlign="center">
        <Button onClick={() => handleMenuItemClick('/strategies')}>
          <span className="me-2">
          <span className="me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              id="Cash-Briefcase--Streamline-Ultimate"
              height="24"
              width="24"
            >
              <desc>
                Cash Briefcase Streamline Icon: https://streamlinehq.com
              </desc>
              <path
                stroke="#1D2155"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 15.937c0 0.932 1.007 1.688 2.25 1.688s2.25 -0.756 2.25 -1.688c0 -0.932 -1.007 -1.687 -2.25 -1.687s-2.25 -0.756 -2.25 -1.688c0 -0.932 1.007 -1.687 2.25 -1.687s2.25 0.755 2.25 1.687"
                strokeWidth="1.5"
              ></path>
              <path
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9.75v1.125"
                strokeWidth="1.5"
              ></path>
              <path
                stroke="#1D2155"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 17.625v1.125"
                strokeWidth="1.5"
              ></path>
              <path
                stroke="#1D2155"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 6.75h18s1.5 0 1.5 1.5v12s0 1.5 -1.5 1.5H3s-1.5 0 -1.5 -1.5v-12s0 -1.5 1.5 -1.5Z"
                strokeWidth="1.5"
              ></path>
              <path
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.342 3.275c-0.0997 -0.29854 -0.2907 -0.55817 -0.5461 -0.74212 -0.2554 -0.18396 -0.5622 -0.28292 -0.8769 -0.28288h-3.838c-0.31474 -0.00004 -0.62152 0.09892 -0.8769 0.28288 -0.25538 0.18395 -0.44643 0.44358 -0.5461 0.74212L7.5 6.75h9l-1.158 -3.475Z"
                strokeWidth="1.5"
              ></path>
            </svg>
          </span>
          </span>
          <Typography sx={{  fontSize: '20px', fontWeight: 500, color: '#1D2155',textTransform: 'capitalize' }}>Strategies</Typography>
        </Button>
      </Grid>
      <Grid item textAlign="center">
        <Button onClick={() => handleMenuItemClick('/famous')}>
          <span className="me-2">
          <span className="me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              id="Video-Player-Movie--Streamline-Ultimate"
              height="24"
              width="24"
            >
              <desc>
                Video Player Movie Streamline Icon: https://streamlinehq.com
              </desc>
              <path
                stroke="#1D2155"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 0.75h19.5s1.5 0 1.5 1.5v19.5s0 1.5 -1.5 1.5H2.25s-1.5 0 -1.5 -1.5V2.25s0 -1.5 1.5 -1.5Z"
                strokeWidth="1.5"
              ></path>
              <path
                stroke="#1D2155"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.238 15.66c-0.1305 0.065 -0.27547 0.0957 -0.42115 0.089 -0.14568 -0.0066 -0.28724 -0.0504 -0.41125 -0.1271 -0.12401 -0.0768 -0.22636 -0.1839 -0.29735 -0.3113 -0.07098 -0.1274 -0.10824 -0.2708 -0.10825 -0.4166V9.10596c0.00001 -0.14583 0.03727 -0.28924 0.10825 -0.41663 0.07099 -0.12738 0.17334 -0.23453 0.29735 -0.31126 0.12401 -0.07673 0.26557 -0.1205 0.41125 -0.12717 0.14568 -0.00667 0.29065 0.02398 0.42115 0.08906l5.789 2.89504c0.1422 0.0709 0.2617 0.1801 0.3453 0.3152 0.0836 0.1351 0.1279 0.2909 0.1279 0.4498 0 0.1588 -0.0443 0.3146 -0.1279 0.4497 -0.0836 0.1351 -0.2031 0.2443 -0.3453 0.3153l-5.789 2.895Z"
                strokeWidth="1.5"
              ></path>
              <path
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M0.75 4.5h22.5"
                strokeWidth="1.5"
              ></path>
              <path
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M0.75 19.5h22.5"
                strokeWidth="1.5"
              ></path>
            </svg>
          </span>
          </span>
          <Typography sx={{  fontSize: '20px', fontWeight: 500, color: '#1D2155',textTransform: 'capitalize' }}>Learn</Typography>
        </Button>
      </Grid>
    </Grid>
    <Divider />
    <Grid container justifyContent="center" mb={3} pt={3}>
      <Typography sx={{ fontWeight: 700, color: '#1D215580', fontSize: '14px' }} variant="sidebar">
        My Strategies
      </Typography>
    </Grid>
    <Grid container justifyContent="center" mb={3}>
      <ul className="nav flex-column">
        {strategies.map((strategy) => (
          <li key={strategy.id} className="nav-item">
            <NavLink to={`/strategy/${strategy.id}`} className="nav-link" onClick={onToggleMenu}>
              <Typography sx={{ lineHeight: '18px', color: '#1D2155', fontWeight: 700 }} variant="sidebar">
                {strategy.name}
              </Typography>
            </NavLink>
          </li>
        ))}
      </ul>
    </Grid>
    <Grid container justifyContent="center">
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onCreateStrategy}
        sx={{
            textTransform: 'capitalize',
          backgroundColor: '#008ED1',
          color: '#fff',
          padding: '15px 55px',
          borderRadius: '0px',
          fontFamily: 'Montserrat, sans-serif',
        }}
      >
        Create Strategy
      </Button>
    </Grid>
    {/* Background image section */}
    <Grid
      item
      sx={{
        height: '75vh',
        backgroundImage: `url(${featureBg})`,
        backgroundSize: { xs: '100%', sm: '80%', md: '60%' },
        backgroundPosition: { xs: 'center', md: 'center right' },
        backgroundRepeat: 'no-repeat',
      }}
    />
  </Paper>
);

export default MobileMenu;
