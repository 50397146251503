import { configureStore } from '@reduxjs/toolkit';
import strategyReducer from '../features/strategySlice';
import drawerReducer from '../features/drawerSlice';

const store = configureStore({
  reducer: {
    strategy: strategyReducer,
    drawer: drawerReducer,
  },
});

export default store;