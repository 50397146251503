import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  Divider,
  IconButton,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import headImage from '../../../src/images/strategiesHeader.png';
import iconGrid from '../../../src/images/icon-key.png';
import myStrategyIcon from '../../../src/images/mystrategylist.png';
import '../../App.css';
import '@fontsource/montserrat';
import {   closeStrategyDrawer, openStrategyDrawer } from '../../features/drawerSlice';
import { useNavigate } from 'react-router-dom';
import { fetchAssets, fetchPublicStrategies, fetchStrategies, resetSettings } from '../../features/strategySlice';
import { assetTypeColors } from './utils';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import CreateStrategyDrawer from './CreateStrategyDrawer ';


const useStyles = makeStyles((theme) => ({
  strategiesContainer: {
    padding: '0 0 10px 0',
    textAlign: 'center',
    backgroundColor: '#EFF0F6',
  },
  header: {
    margin: '18px 20px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      margin: '18px 40px',
    },
  },
  card: {
    borderRadius: 10,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
    [theme.breakpoints.up('md')]: {
      padding: 20,
    },
    flex: 1,
  },
  strategyButton: {
    marginTop: 20,
    backgroundColor: '#008ED1',
    padding: '11px 20px!important',
    borderRadius: '0%!important',
    [theme.breakpoints.up('md')]: {
      padding: '11px 30px!important',
    },
  },

  strategyIcon: {
    marginBottom: 10,
    height: 60,
    [theme.breakpoints.up('md')]: {
      height: 80,
    },
    width: 'auto',
  },
  strategyDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 0',
    width: '100%',
    marginBottom: 5,
  },
  detailText: {
    display: 'flex',
    alignItems: 'center',
  },
  detailIcon: {
    marginRight: 5,
    borderRadius: '50%',
    width: 12,
    height: 12,
    [theme.breakpoints.up('md')]: {
      width: 14,
      height: 14,
    },
  },
  viewIcon: {
    backgroundColor: '#008ED1',
    padding: 15,
    borderRadius: '0%',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: 17,
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'stretch',
  },
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    borderRadius: '0%!important',
  },
  transformIsEven: {
    transform: 'translate(0, -1px)',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(140px, -1px)',
    },
  },
  sliderContainer: {
    position: 'relative',
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
        display: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        overflow: 'auto',
        width: '386px'
      },
  },
  sliderContent: {
    display: 'flex',
    transition: 'transform 0.5s ease-in-out',
  },
  slide: {
    display: 'flex',
    alignItems: 'stretch',
    minWidth: '100%',

    [theme.breakpoints.up('sm')]: {
      minWidth: '50%',
    },
    boxSizing: 'border-box',
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 26px 0 22px',
    },
  },
  bullets: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    listStyleType: 'none',
    padding: 0,
    position: 'absolute',
    bottom: '90%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  bullet: {
    width: 8,
    height: 8,
    [theme.breakpoints.up('md')]: {
      width: 10,
      height: 10,
    },
    borderRadius: '50%',
    backgroundColor: '#ccc',
    margin: '0 5px',
    cursor: 'pointer',
  },
  activeBullet: {
    backgroundColor: '#008ED1',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    zIndex: 1,
  },
  arrowLeft: {
    left: 0,
  },
  arrowRight: {
    right: 0,
  },
}));

const Strategies = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const strategies = useSelector((state) => state.strategy.strategies);
  const publicStrategies = useSelector((state) => state.strategy.publicStrategies);
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(fetchAssets());
        await Promise.all([
          dispatch(fetchStrategies()),
          dispatch(fetchPublicStrategies())
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const formatWeight = (weight) => `${(parseFloat(weight) * 100).toFixed(0)}%`;

  const handleOpenDrawer = () => {
    dispatch(openStrategyDrawer());
  };

  useEffect(() => {
    dispatch(fetchAssets())
      .then(() => {
        dispatch(fetchStrategies());
        dispatch(fetchPublicStrategies());
      })
      .catch((error) => {
        console.error('Error fetching assets:', error);
      });
  }, [dispatch]);

  const slidesToShow = isMobile ? 1 : 2;
  const totalSlides = strategies.length;
  const totalBullets = Math.ceil(totalSlides / slidesToShow);

  const handleBulletClick = (index) => {
    const maxStartIndex = Math.max(0, totalSlides - slidesToShow);
    const newIndex = Math.min(index, maxStartIndex);
    setCurrentSlide(newIndex);
  };

  const handlePrevClick = () => {
    const maxStartIndex = Math.max(0, totalSlides - slidesToShow);
    const newIndex = Math.min(currentSlide - slidesToShow, maxStartIndex);
    setCurrentSlide(Math.max(0, newIndex));
  };

  const handleNextClick = () => {
    const maxStartIndex = Math.max(0, totalSlides - slidesToShow);
    const newIndex = Math.min(currentSlide + slidesToShow, maxStartIndex);
    setCurrentSlide(newIndex);
  };

  const handleStrategyNavigation = (strategyId) => {
    dispatch(resetSettings());
    navigate(`/strategy/${strategyId}`);
  };


  const isEven = totalSlides % slidesToShow === 0;

  return (
    <Grid container className={classes.strategiesContainer}>
      <CreateStrategyDrawer open={isDrawerOpen} onClose={() => dispatch(closeStrategyDrawer())} />

      <Box className={classes.header}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
          <Typography
              textAlign="left"
              variant="head"
              gutterBottom
            >
              Strategies
            </Typography>
          </Box>
          {strategies.length > 0 && (
            <Box>

                {isMobile ? (
                    <Button
                    variant="contained"
                    onClick={handleOpenDrawer}
                  >
                    <Typography
                      variant="body1"
                      style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textTransform: 'capitalize' }}
                    >
                      + New
                    </Typography>
                  </Button>

                ) : (
                    <Button
                variant="contained"
                className={classes.strategyButton}
                onClick={handleOpenDrawer}
              >
                <Typography
                  variant="body1"
                  style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textTransform: 'capitalize' }}
                >
                  Create your Strategy
                </Typography>
              </Button>
                )}

            </Box>
          )}
        </Box>

        {strategies.length < 1 && (
          <Grid container justifyContent="center">
            <Grid item xs={12} md={5}>
              <img src={headImage} alt="Strategies Illustration" style={{ maxWidth: '100%' }} />



              <Button
                variant="contained"
                className={classes.strategyButton}
                onClick={handleOpenDrawer}
              >
                <Typography
                  variant="body1"
                  style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textTransform: 'capitalize' }}
                >
                  Create your Strategy
                </Typography>
              </Button>
            </Grid>
            <Divider sx={{ width: '100%', marginTop: 3, opacity: 1 }} />
          </Grid>
        )}
      </Box>
      <Divider />
      <Grid container sx={{ background: '#fff', paddingBottom: '25px', position: 'relative' }}>
        {strategies.length > 0 && (
          <Grid item p={2} lg={3} mb={2} sx={{display: isMobile ? 'flex' : '', width: isMobile? '100%' : 'unset',justifyContent: isMobile ? "center" : "" }}>
            <Box sx={{ display: 'inline-block', float: 'inline-start', paddingLeft: '22px' }}>
              <Typography variant="body3" style={{ fontSize: '30px', fontWeight: 600 }} gutterBottom>
                My Strategies
              </Typography>
              <Divider sx={{ borderBottomWidth: 6, width: '100px', borderColor: '#00D0AF', marginTop: '1px', marginLeft: isMobile ? "45px": "", opacity: 1 }} />
            </Box>
          </Grid>
        )}

        {strategies.length > 2 ? (
          <Box className={classes.sliderContainer}>
            {!isMobile && <ArrowBackIos className={`${classes.arrow} ${classes.arrowLeft}`} onClick={handlePrevClick} />}

            <Box
              className={classes.sliderContent}
              style={{ transform:!isMobile ? `translateX(-${(currentSlide * 100) / slidesToShow}%)` :  `translateX(-${(currentSlide * 100) / slidesToShow}%)`}}
            >
              {strategies.map((strategy, index) => (
                <Box key={index} className={classes.slide}>
                  <Card className={classes.gridItemCard} sx={{ backgroundColor: '#E5F4FA' }}>
                    <CardContent className={classes.cardContent}>
                      <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item xs={3} md={3}>
                          <img src={myStrategyIcon} alt="icon" className={classes.strategyIcon} />
                        </Grid>
                        <Grid item xs={8} md={8} textAlign="left">
                          <Typography
                            variant="body3"
                            sx={{ color: '#008ED1', fontWeight: 600, lineHeight: isMobile ? '20px' :'24px' }}
                            gutterBottom
                          >
                            {strategy.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ width: '100%', marginBottom: 3, opacity: 1 }} />
                      <Grid item style={{ padding: '15px 0px 50px 0px', width: isMobile ? '100%' : 'calc(100% - 10px)' }}>
                        {strategy.assets.map((detail, i) => {
                          const assetType = detail.asset_type || 'Unknown';
                          const assetColor = assetTypeColors[assetType] || assetTypeColors.Unknown;
                          return (
                            <Box key={i} className={classes.strategyDetail}>
                              <Box className={classes.detailText}>
                                <Box
                                  className={classes.detailIcon}
                                  style={{ backgroundColor: assetColor, marginRight: '15px' }}
                                ></Box>
                                <Typography variant="h6" align={isMobile ? "left" : "center"}>
                                  {detail.ticker} {detail.asset_description} ({assetType})
                                </Typography>
                              </Box>
                              <Typography style={{ fontWeight: 'bold' }} variant="body2">
                                {formatWeight(detail.weight)}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Grid container justifyContent="flex-end">
                      <IconButton
                        sx={{ padding: 0, borderRadius: '2px' }}
                        onClick={() => handleStrategyNavigation(strategy.id)}

                      >
                        <Box className={classes.viewIcon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="-0.5 -0.5 14 14"
                            id="Eye--Streamline-Platinum"
                            height="24"
                            width="24"
                          >
                            <desc>Eye Streamline Icon: https://streamlinehq.com</desc>
                            <g id="eye">
                              <path
                                id="Vector"
                                stroke="#ffff"
                                d="M6.500000000000001 1.6250000000000002C3.2500000000000004 1.6250000000000002 0.325 6.500000000000001 0.325 6.500000000000001S3.2500000000000004 11.375000000000002 6.500000000000001 11.375000000000002s6.175000000000001 -4.875000000000001 6.175000000000001 -4.875000000000001S9.750000000000002 1.6250000000000002 6.500000000000001 1.6250000000000002Z"
                                strokeWidth="1"
                              ></path>
                              <path
                                id="Vector_2"
                                stroke="#ffff"
                                d="M8.395833333333334 6.500000000000001a1.8958333333333335 1.8958333333333335 0 1 1 -3.791666666666667 0 1.8958333333333335 1.8958333333333335 0 0 1 3.791666666666667 0Z"
                                strokeWidth="1"
                              ></path>
                            </g>
                          </svg>
                        </Box>
                      </IconButton>
                    </Grid>
                  </Card>
                </Box>
              ))}
            </Box>
            {!isMobile && <ArrowForwardIos className={`${classes.arrow} ${classes.arrowRight}`} onClick={handleNextClick} />}

          </Box>
        ) : (
          <Grid
            container
            justifyContent={`${isEven ? 'center' : 'flex-start'}`}
            className={isEven ? '' : classes.transformIsEven}
            spacing={4}
          >
            {strategies.map((strategy, index) => (
              <Grid item xs={11} sm={6} md={5} key={index} className={classes.gridItem}>
                <Card className={classes.gridItemCard} sx={{ backgroundColor: '#E5F4FA' }}>
                  <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="flex-start" alignItems="center">
                      <Grid item xs={3} md={3}>
                        <img src={myStrategyIcon} alt="icon" className={classes.strategyIcon} />
                      </Grid>
                      <Grid item xs={8} md={8} textAlign="left">
                        <Typography
                          variant="body3"
                          sx={{ color: '#008ED1', fontWeight: 600, lineHeight: isMobile ? '20px' :'24px' }}
                          gutterBottom
                        >
                          {strategy.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ width: '100%', marginBottom: 3, opacity: 1 }} />
                    <Grid item style={{ padding: '15px 0px 50px 0px', width: isMobile ? '100%' : 'calc(100% - 10px)' }}>
                      {strategy.assets.map((detail, i) => {
                        const assetType = detail.asset_type || 'Unknown';
                        const assetColor = assetTypeColors[assetType] || assetTypeColors.Unknown;
                        return (
                          <Box key={i} className={classes.strategyDetail}>
                            <Box className={classes.detailText}>
                              <Box
                                className={classes.detailIcon}
                                style={{ backgroundColor: assetColor, marginRight: '15px' }}
                              ></Box>
                              <Typography variant="h6" align={isMobile ? "left" : "center"}>
                                {detail.ticker} {detail.asset_description} ({assetType})
                              </Typography>
                            </Box>
                            <Typography style={{ fontWeight: 'bold' }} variant="body2">
                              {formatWeight(detail.weight)}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Grid container justifyContent="flex-end">
                    <IconButton
                      sx={{ padding: 0, borderRadius: '2px' }}
                      onClick={() => handleStrategyNavigation(strategy.id)}
                    >
                      <Box className={classes.viewIcon}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="-0.5 -0.5 14 14"
                          id="Eye--Streamline-Platinum"
                          height="24"
                          width="24"
                        >
                          <desc>Eye Streamline Icon: https://streamlinehq.com</desc>
                          <g id="eye">
                            <path
                              id="Vector"
                              stroke="#ffff"
                              d="M6.500000000000001 1.6250000000000002C3.2500000000000004 1.6250000000000002 0.325 6.500000000000001 0.325 6.500000000000001S3.2500000000000004 11.375000000000002 6.500000000000001 11.375000000000002s6.175000000000001 -4.875000000000001 6.175000000000001 -4.875000000000001S9.750000000000002 1.6250000000000002 6.500000000000001 1.6250000000000002Z"
                              strokeWidth="1"
                            ></path>
                            <path
                              id="Vector_2"
                              stroke="#ffff"
                              d="M8.395833333333334 6.500000000000001a1.8958333333333335 1.8958333333333335 0 1 1 -3.791666666666667 0 1.8958333333333335 1.8958333333333335 0 0 1 3.791666666666667 0Z"
                              strokeWidth="1"
                            ></path>
                          </g>
                        </svg>
                      </Box>
                    </IconButton>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}




      </Grid>

      {/* ------- Recommended Strategies ---------- */}
      <Grid container justifyContent="center" pt={3} pb={3} sx={{ position: 'relative' }}>
        {strategies.length > 2 && (
              <ul className={classes.bullets}>
              {Array.from({ length: totalBullets }).map((_, index) => {
                const isActive =
                  currentSlide >= totalSlides - slidesToShow
                    ? index === totalBullets - 1
                    : Math.floor(currentSlide / slidesToShow) === index;
                return (
                  <li
                    key={index}
                    className={`${classes.bullet} ${isActive ? classes.activeBullet : ''}`}
                    onClick={() => handleBulletClick(index * slidesToShow)}
                  />
                );
              })}
            </ul>

        )}

        <Typography style={{ color: '#1D2155', fontSize: '27px' }} variant="h4" gutterBottom>
          Famous Strategies
        </Typography>
      </Grid>
      {isLoading ? (
         <Grid container justifyContent="center">
         <CircularProgress />
       </Grid>

      ) :(
        <Grid container justifyContent="center" spacing={4}>
        {publicStrategies.map((strategy, index) => (
          <Grid item xs={11} sm={6} md={5} key={index} className={classes.gridItem}>
            <Card className={classes.gridItemCard}>
              <CardContent className={classes.cardContent}>
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <img src={iconGrid} alt="icon" className={classes.strategyIcon} />
                  </Grid>
                  <Grid item xs={8} md={8} textAlign="left">
                    <Typography variant="body3" sx={{ color: '#008ED1', fontWeight: 600, lineHeight: isMobile ? '20px' :'24px' }} gutterBottom>
                      {strategy.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ width: '100%', marginBottom: 3, opacity: 1 }} />
                <Grid item style={{ padding: '15px 0px 50px 0px', width: isMobile ? '100%' : 'calc(100% - 10px)' }}>
                  {strategy.assets.map((detail, i) => {
                    const assetType = detail.asset_type || 'Unknown';
                    const assetColor = assetTypeColors[assetType] || assetTypeColors.Unknown;
                    return (
                      <Box key={i} className={classes.strategyDetail}>
                        <Box className={classes.detailText}>
                          <Box
                            className={classes.detailIcon}
                            style={{ backgroundColor: assetColor, marginRight: '15px' }}
                          ></Box>
                          <Typography variant="h6" align={isMobile? "left" : "center"}>
                            {detail.ticker} {detail.asset_description} ({assetType})
                          </Typography>
                        </Box>
                        <Typography style={{ fontWeight: 'bold' }} variant="body2">
                          {formatWeight(detail.weight)}
                        </Typography>
                      </Box>
                    );
                  })}
                </Grid>
              </CardContent>
              <Divider />
              <Grid container justifyContent="flex-end">
                <IconButton
                  sx={{ padding: 0, borderRadius: '2px' }}
                  onClick={() => handleStrategyNavigation(strategy.id)}
                >
                  <Box className={classes.viewIcon}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="-0.5 -0.5 14 14"
                      id="Eye--Streamline-Platinum"
                      height="24"
                      width="24"
                    >
                      <desc>Eye Streamline Icon: https://streamlinehq.com</desc>
                      <g id="eye">
                        <path
                          id="Vector"
                          stroke="#ffff"
                          d="M6.500000000000001 1.6250000000000002C3.2500000000000004 1.6250000000000002 0.325 6.500000000000001 0.325 6.500000000000001S3.2500000000000004 11.375000000000002 6.500000000000001 11.375000000000002s6.175000000000001 -4.875000000000001 6.175000000000001 -4.875000000000001S9.750000000000002 1.6250000000000002 6.500000000000001 1.6250000000000002Z"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Vector_2"
                          stroke="#ffff"
                          d="M8.395833333333334 6.500000000000001a1.8958333333333335 1.8958333333333335 0 1 1 -3.791666666666667 0 1.8958333333333335 1.8958333333333335 0 0 1 3.791666666666667 0Z"
                          strokeWidth="1"
                        ></path>
                      </g>
                    </svg>
                  </Box>
                </IconButton>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>

      )}

      <Grid container justifyContent="center" p={2} sx={{ width: '100%' }}>
        <Grid item xs={12} sm={6} md={9.5}>
          <Typography variant="body2" align={isMobile ? "center" : "left"} color="text.secondary">
            Copyright © PensionCraft Ltd. All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Strategies;
