import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Button, Divider, Drawer, TextField, IconButton, Grid, Paper, InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import Autocomplete from '@mui/material/Autocomplete';
import { clonePublicStrategy, fetchStrategies } from '../../features/strategySlice';
import { closeStrategyDrawer, clearDrawer, setStrategyData } from '../../features/drawerSlice';
import { axiosInstance } from '../../axiosConfig';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    width: '700px',
    height: '100%',
    maxWidth: 900,
    padding: theme.spacing(3),
    backgroundColor: '#F6F8FB',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(2),
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  addButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#00D0AF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#00B89C',
    },
  },
  actionButtons: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
  button: {
    display: 'flex',
    width: '100%',
    borderRadius: '0px!important',
    padding: '12px 16px!important',
    textTransform: "capitalize",
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  percentageCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 55,
    height: 55,
  },
  removeButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));


const InfoDrawer = ({ open, onClose }) => {
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '500px' },
            padding: '24px',
            backgroundColor: '#F6F8FB',
          },
        }}
      >
        <Grid container p={2}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Information UK funds
              </Typography>
              <IconButton onClick={onClose} sx={{ padding: '12px', borderRadius: '50%', background: '#EFF0F6' }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Paper elevation={2} sx={{ padding: '24px', marginBottom: '15px' }}>
              <Typography variant="h6" sx={{ marginBottom: '16px', color: '#1D2155' }}>
              The first tab on the spreadsheet lists the predefined funds in the app on the left hand side and then identifies the single closest matching UK fund to the right of it.<p/> This is useful if you want to recreate one of the predefined famous strategies with funds available in the UK.
<p />The second tab allows you to match a list of UK funds to the closest matching predefined fund in the app. <p />This is useful if you want to choose funds to recreate your own strategy in the app based on a UK fund portfolio.
<p />The spreadsheet with the mapping is <a href="https://docs.google.com/spreadsheets/d/1dzNh3ep2928KqWJ4IKyODXeL3Orf-u4em2YLmN58yug/edit?gid=0#gid=0" target="_blank" rel="noopener noreferrer">here</a>
              </Typography>


            </Paper>


          </Box>
        </Grid>
      </Drawer>
    );
  };

const CreateStrategyDrawer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = useSelector((state) => state.drawer.open);
  const currentStrategy = useSelector((state) => state.drawer.strategyData) || {};
  const [strategyName, setStrategyName] = useState('');
  const [funds, setFunds] = useState([{ fund: '', allocation: '' }]);
  const [errorMessage, setErrorMessage] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const assets = useSelector(state => state.strategy.assets);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);



  const publicStrategies = useSelector(
    (state) => state.strategy.publicStrategies
  );



  useEffect(() => {
    if (open && currentStrategy) {
      setStrategyName(currentStrategy.name || '');
      setFunds(currentStrategy.assets?.map(asset => ({
        fund: asset.asset_ticker,
        allocation: asset.weight.toString()
      })) || [{ fund: '', allocation: '' }]);
    } else {
      resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const resetForm = () => {
    setStrategyName('');
    setFunds([{ fund: '', allocation: '' }]);
    setErrorMessage('');
  };


  const handleAddFund = () => {
    setFunds([...funds, { fund: '', allocation: '' }]);
  };

  const handleRemoveFund = (index) => {
    const newFunds = funds.filter((_, i) => i !== index);
    setFunds(newFunds);
  };

  const handleFundChange = (index, field, value) => {
    const newFunds = [...funds];
    if (field === 'allocation') {
      const cleanValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');

      if (cleanValue === '') {
        newFunds[index][field] = '';
      } else {
        let numValue = parseFloat(cleanValue);

        if (isNaN(numValue)) {
          numValue = 0;
        } else if (numValue > 100) {
          numValue = 100;
        }

        newFunds[index][field] = (numValue / 100).toFixed(2);
      }
    } else {
      newFunds[index][field] = value;
    }
    setFunds(newFunds);
  };

  const hasDuplicateAssets = () => {
    const assetTickers = funds.map(fund => fund.fund);
    return assetTickers.length !== new Set(assetTickers).size;
  };

  const handleSaveStrategy = async () => {
    const totalAllocation = parseFloat(
      funds.reduce((total, item) => total + parseFloat(item.allocation || 0), 0).toFixed(2)
    );

    if (hasDuplicateAssets()) {
      setErrorMessage('You cannot add the same asset more than once.');
      return;
    }

    if (strategyName && totalAllocation === 1.00) {
      let newStrategy = {
        id: currentStrategy?.id || Date.now(),
        name: strategyName,
        assets: funds.map((item) => ({
          asset_ticker: item.fund,
          weight: parseFloat(item.allocation),
        })),
      };

      try {
        if (currentStrategy?.id) {
          if (publicStrategies.some((publicStrat) => publicStrat.id === currentStrategy.id)) {
            dispatch(clonePublicStrategy(newStrategy)).then((response) => {
              if (response?.payload) {
                dispatch(setStrategyData(response.payload));
                dispatch(fetchStrategies());
                dispatch(closeStrategyDrawer());
                resetForm();
                navigate('/strategies');
              } else {
                console.error('Failed to clone public strategy');
              }
            });
          } else {
            const response = await axiosInstance.put(`/strategies/${currentStrategy.id}/`, newStrategy);

            if (response.status === 200 || response.status === 201) {
              dispatch(fetchStrategies());
              dispatch(closeStrategyDrawer());
              resetForm();
              navigate('/strategies');
            } else {
              console.error('Failed to update strategy');
            }
          }
        } else {
          // For new strategies, create a new one with a POST request
          const response = await axiosInstance.post('/strategies/', newStrategy);

          if (response.status === 200 || response.status === 201) {
            dispatch(fetchStrategies());
            dispatch(closeStrategyDrawer());
            resetForm();
            navigate('/strategies');
          } else {
            console.error('Failed to create new strategy');
          }
        }
      } catch (error) {
        console.error('Error saving strategy:', error);
      }
    } else {
      alert('Please ensure the total allocation is 100%');
    }
  };


  const totalAllocation = parseFloat(funds.reduce((total, item) => total + parseFloat(item.allocation || 0), 0).toFixed(2));

  useEffect(() => {
    if (totalAllocation > 1) {
      setErrorMessage(`Distribute ${Math.round(totalAllocation * 100 - 100)}% less to save your strategy`);
    } else {
      setErrorMessage('');
    }
  }, [totalAllocation]);

  const onHandleClose = () => {
    dispatch(clearDrawer());
    dispatch(closeStrategyDrawer());
    resetForm();
  }

  return (
    <Drawer anchor="right" open={open} onClose={onHandleClose}>
        <Grid container p={2}>
        <Box className={classes.header}>
          <Grid item xs={2} sm={1}>
            <IconButton onClick={onHandleClose} sx={{padding: '12px', borderRadius: '50%', background:'#EFF0F6'}}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} sm={10} sx={{ marginLeft: { xs: '0', sm: '170px' } }}>
          <Box sx={{ display: 'inline-block' }}>
          <Typography variant="h3" sx={{fontWeight: 600, fontSize: { xs: '1.5rem', sm: '2rem' } }}>
            {currentStrategy.name ? 'Edit your Strategy' : 'Create your Strategy'}
          </Typography>

            <Divider sx={{ borderBottomWidth: 6, width: '100px', borderColor: '#00D0AF', margin: { xs: '6px 0', sm: '6px 100px' }, opacity: 1 }} />
        </Box>
          </Grid>
        </Box>
        </Grid>
      <Box className={classes.drawerContent}>
        <Paper elevation={2} sx={{ padding: '24px', marginBottom: '15px' }}>
          <Typography variant="h6">Strategy Name</Typography>
          <TextField
            fullWidth
            label="Strategy Name"
            placeholder="Please type the name of your strategy"
            variant="outlined"
            margin="normal"
            value={strategyName}
            onChange={(e) => setStrategyName(e.target.value)}
          />
        </Paper>
        <Grid container className={classes.sectionTitle}>
          <Grid item xs={12} md={6}>
            <Typography textAlign={isMobile? "center" : ''} variant="subtitle1">Add funds to your strategy</Typography>
          </Grid>

          <Grid item xs={12} md={4} className={classes.percentageCircle}>
            <CircularProgressWithLabel value={totalAllocation * 100} />
          </Grid>
          {errorMessage && (
            <Grid container xs={12} sm={6}>
              <Typography variant="body2" color="error" textAlign={isMobile? "center": ''}
              sx={{ marginRight: isMobile? 0 :2, marginBottom: isMobile? '10px': '' }}>
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>

        {funds.map((item, index) => (
          <Paper key={index} elevation={2} sx={{ padding: '24px', marginBottom: '15px' }}>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={7} lg={6}>
                <Typography variant="sidebar" sx={{fontSize: '16px', color: '#1D2155'}}>Fund</Typography>
                <Autocomplete
                  fullWidth
                  options={assets}
                  getOptionLabel={(option) => `${option.label} - ${option.description}`}
                  value={item.fund ? assets.find(asset => asset.label === item.fund) : null}
                  onChange={(event, newValue) => handleFundChange(index, 'fund', newValue?.label || '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Type or select fund"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{color:'#008ED1'}}/>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={10} sm={3} ml={{ xs: 0, sm: 2 }}>
              <Typography variant="sidebar" sx={{fontSize: '16px', color: '#1D2155'}}>Allocation</Typography>
                <TextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  margin="normal"
                  placeholder='%'
                  value={item.allocation === '' ? '' : `${(parseFloat(item.allocation) * 100).toFixed(0)}`}
                  onChange={(e) => handleFundChange(index, 'allocation', e.target.value)}
                  inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={2} mt={{ xs: 2, sm: 3 }}>
                <IconButton
                  className={classes.removeButton}
                  onClick={() => handleRemoveFund(index)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Recycle-Bin-2--Streamline-Core-Remix" height="50" width="24"><desc>Recycle Bin 2 Streamline Icon: https://streamlinehq.com</desc><g id="Free Remix/Interface Essential/recycle-bin-2--remove-delete-empty-bin-trash-garbage"><path id="Union"
                 fill="#008ED1" fillRule="evenodd" d="M5.85095 2.22595C6.1557 1.9212 6.56902 1.75 7 1.75c0.43098 0 0.8443 0.1712 1.14905 0.47595 0.21471 0.21472 0.36314 0.48334 0.43209 0.77405H5.41886c0.06895 -0.29071 0.21737 -0.55933 0.43209 -0.77405ZM3.64589 3c0.08462 -0.75713 0.42371 -1.46758 0.96762 -2.011485C5.24645 0.355579 6.10489 0 7 0c0.89511 0 1.75355 0.355579 2.38649 0.988515C9.9304 1.53242 10.2695 2.24287 10.3541 3H13c0.4832 0 0.875 0.39175 0.875 0.875s-0.3918 0.875 -0.875 0.875h-0.875v7.625c0 0.431 -0.1712 0.8443 -0.476 1.149 -0.3047 0.3048 -0.718 0.476 -1.149 0.476h-7c-0.43098 0 -0.8443 -0.1712 -1.14905 -0.476 -0.30475 -0.3047 -0.47595 -0.718 -0.47595 -1.149V4.75H1c-0.483249 0 -0.875 -0.39175 -0.875 -0.875S0.516751 3 1 3h2.64589Zm7.22911 9.375V4.75h-7.75v7.625c0 0.0995 0.03951 0.1948 0.10983 0.2652 0.07033 0.0703 0.16571 0.1098 0.26517 0.1098h7c0.0995 0 0.1948 -0.0395 0.2652 -0.1098 0.0703 -0.0704 0.1098 -0.1657 0.1098 -0.2652ZM5.5 6.00146c0.34518 0 0.625 0.27983 0.625 0.625V10.628c0 0.3451 -0.27982 0.625 -0.625 0.625s-0.625 -0.2799 -0.625 -0.625V6.62646c0 -0.34517 0.27982 -0.625 0.625 -0.625Zm3.625 0.625c0 -0.34517 -0.27982 -0.625 -0.625 -0.625s-0.625 0.27983 -0.625 0.625V10.628c0 0.3451 0.27982 0.625 0.625 0.625s0.625 -0.2799 0.625 -0.625V6.62646Z" clipRule="evenodd" strokeWidth="1"></path></g></svg>
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))}
       <Paper elevation={2} sx={{ padding: '24px' }}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>Add another fund</Typography>
          <Button onClick={handleAddFund}>
            <AddCircleIcon />
          </Button>
        </Grid>
      </Paper>

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
        <Button
          startIcon={<InfoIcon sx={{ color: '#008ED1' }} />}
          onClick={() => setInfoDrawerOpen(true)}
          sx={{
            color: '#008ED1',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline'
            }
          }}
        >
          Can't Match Your UK Fund? Learn How
        </Button>
      </Box>


        <InfoDrawer
        open={infoDrawerOpen}
        onClose={() => setInfoDrawerOpen(false)}
      />
        <Grid className={classes.actionButtons}>
          <Grid item xs={12} sm={5} mr={{ sm: 2 }}>
            <Button
              variant="contained"
              className={classes.button}
              sx={{ backgroundColor: '#008ED1', textTransform: "capitalize", }}
              onClick={handleSaveStrategy}
            >
              Save Strategy
            </Button>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Button
              variant="text"
              className={classes.button}
              sx={{ backgroundColor: 'white', textTransform: "capitalize", }}
              onClick={onHandleClose}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default CreateStrategyDrawer;
