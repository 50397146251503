import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import "./Sidebar.css";
import logo from "../../src/images/pensioncraft-logo.png";
import { fetchStrategies, resetSettings, resetStrategyData } from "../features/strategySlice";
import {  Typography, CircularProgress } from "@mui/material";
import {  clearDrawer, openStrategyDrawer } from "../features/drawerSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const strategies = useSelector((state) => state.strategy.strategies);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadStrategies = async () => {
      setIsLoading(true);
      await dispatch(fetchStrategies());
      setIsLoading(false);
    };
    loadStrategies();
  }, [dispatch]);

  const handleStrategyClick = (strategyId) => {
    dispatch(resetStrategyData());
    dispatch(clearDrawer());
    dispatch(resetSettings());


    navigate(`/strategy/${strategyId}`);
  };

  return (
    <div
      className="d-flex flex-column p-3"
      style={{
        width: "230px",
        height: "100vh",
        background: "#ffff",
        borderRight: "2px solid #EFF0F6",
      }}
    >
      <a
        href="/"
        className="d-flex align-items-center justify-content-center mb-3 mb-md-0 text-dark text-decoration-none"
      >
        <span className="fs-4 mb-3">
          <img src={logo} alt="logo" />
        </span>
      </a>

      <ul className="nav flex-column">
        <li className="nav-item">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            end
          >
            <span className="me-2">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 21.3749H22.25C22.85 21.3749 23.25 20.9749 23.25 20.3749V4.37494C23.25 3.77494 22.85 3.37494 22.25 3.37494H1.75C1.15 3.37494 0.75 3.77494 0.75 4.37494V20.3749C0.75 20.8749 1.25 21.3749 1.75 21.3749Z"
                  stroke="#1D2155"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.25 3.37494V21.3749"
                  stroke="#1D2155"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.54999 7.07489H3.54999"
                  stroke="#1D2155"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.54999 10.3749H3.54999"
                  stroke="#1D2155"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.54999 13.575H3.54999"
                  stroke="#1D2155"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.55 16.7748H5.25"
                  stroke="#1D2155"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <Typography variant="sidebar">Home</Typography>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/strategies"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                id="Cash-Briefcase--Streamline-Ultimate"
                height="24"
                width="24"
              >
                <desc>
                  Cash Briefcase Streamline Icon: https://streamlinehq.com
                </desc>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 15.937c0 0.932 1.007 1.688 2.25 1.688s2.25 -0.756 2.25 -1.688c0 -0.932 -1.007 -1.687 -2.25 -1.687s-2.25 -0.756 -2.25 -1.688c0 -0.932 1.007 -1.687 2.25 -1.687s2.25 0.755 2.25 1.687"
                  strokeWidth="1.5"
                ></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9.75v1.125"
                  strokeWidth="1.5"
                ></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 17.625v1.125"
                  strokeWidth="1.5"
                ></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 6.75h18s1.5 0 1.5 1.5v12s0 1.5 -1.5 1.5H3s-1.5 0 -1.5 -1.5v-12s0 -1.5 1.5 -1.5Z"
                  strokeWidth="1.5"
                ></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.342 3.275c-0.0997 -0.29854 -0.2907 -0.55817 -0.5461 -0.74212 -0.2554 -0.18396 -0.5622 -0.28292 -0.8769 -0.28288h-3.838c-0.31474 -0.00004 -0.62152 0.09892 -0.8769 0.28288 -0.25538 0.18395 -0.44643 0.44358 -0.5461 0.74212L7.5 6.75h9l-1.158 -3.475Z"
                  strokeWidth="1.5"
                ></path>
              </svg>
            </span>
            <Typography variant="sidebar"> Strategies</Typography>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/famous"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                id="Video-Player-Movie--Streamline-Ultimate"
                height="24"
                width="24"
              >
                <desc>
                  Video Player Movie Streamline Icon: https://streamlinehq.com
                </desc>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 0.75h19.5s1.5 0 1.5 1.5v19.5s0 1.5 -1.5 1.5H2.25s-1.5 0 -1.5 -1.5V2.25s0 -1.5 1.5 -1.5Z"
                  strokeWidth="1.5"
                ></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.238 15.66c-0.1305 0.065 -0.27547 0.0957 -0.42115 0.089 -0.14568 -0.0066 -0.28724 -0.0504 -0.41125 -0.1271 -0.12401 -0.0768 -0.22636 -0.1839 -0.29735 -0.3113 -0.07098 -0.1274 -0.10824 -0.2708 -0.10825 -0.4166V9.10596c0.00001 -0.14583 0.03727 -0.28924 0.10825 -0.41663 0.07099 -0.12738 0.17334 -0.23453 0.29735 -0.31126 0.12401 -0.07673 0.26557 -0.1205 0.41125 -0.12717 0.14568 -0.00667 0.29065 0.02398 0.42115 0.08906l5.789 2.89504c0.1422 0.0709 0.2617 0.1801 0.3453 0.3152 0.0836 0.1351 0.1279 0.2909 0.1279 0.4498 0 0.1588 -0.0443 0.3146 -0.1279 0.4497 -0.0836 0.1351 -0.2031 0.2443 -0.3453 0.3153l-5.789 2.895Z"
                  strokeWidth="1.5"
                ></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M0.75 4.5h22.5"
                  strokeWidth="1.5"
                ></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M0.75 19.5h22.5"
                  strokeWidth="1.5"
                ></path>
              </svg>
            </span>
            <Typography variant="sidebar"> Learn</Typography>
          </NavLink>
        </li>
        <hr />
        <div style={{ padding: "5px 15px" }}>
          <a
            href="/"
            className="d-flex align-items-center text-dark text-decoration-none"
            onClick={(e) => {
              e.preventDefault();
              dispatch(openStrategyDrawer());
            }}
          >
            <Typography
              sx={{ fontWeight: 700, color: "#1D215580", fontSize: "14px" }}
              variant="sidebar"
            >
              {" "}
              My Strategies
            </Typography>
            <AddCircleOutlinedIcon color="primary" className="ms-2" />
          </a>
        </div>
      </ul>
      <ul className="nav flex-column">
        {isLoading ? (
          <li className="nav-item d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
            <CircularProgress size={24} />
          </li>
        ) : (
          strategies.map((strategy) => (
            <li key={strategy.id} className="nav-item">
              <NavLink
                to={`/strategy/${strategy.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleStrategyClick(strategy.id);
                }}
                className="nav-link"
              >
                <Typography sx={{ lineHeight: "18px" }} variant="sidebar">
                  {strategy.name}
                </Typography>
              </NavLink>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
