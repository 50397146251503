import React from 'react';
import { Box, Typography } from '@mui/material';

const NoDataMessage = ({ message }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100%"
  >
    <Typography variant="h6" gutterBottom>
      No Data available
    </Typography>
    <Typography variant="body1" gutterBottom>
      {message}
    </Typography>

  </Box>
);

export default NoDataMessage;