import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import NoDataMessage from './NoDataMessage';
import { makeStyles } from "@mui/styles";
import { ExportButton } from './StrategyContent';
import { Box } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  chartContainer: {
    flex: 1,
    position: 'relative',
    width: '100%',
    minHeight: '40vh',
    '@media (max-width: 600px)': {
      minHeight: '30vh',
    },
    '@media (min-width: 700px)': {
        minHeight: '28vh',
      },
    '@media (min-width: 1200px)': {
      minHeight: '28vh',
    },
  },
}));

const DrawDown = ({ data, isFullscreen }) => {
  const classes = useStyles();
  const chartRef = useRef(null);

  useEffect(() => {
    const resizeChart = () => {
      if (chartRef.current) {
        chartRef.current.resize();
      }
    };

    resizeChart();

    window.addEventListener('resize', resizeChart);

    return () => {
      window.removeEventListener('resize', resizeChart);
    };
  }, [isFullscreen]);

  if (!data || data.length === 0) {
    return <NoDataMessage message="Please click calculate button on settings." />;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Years',
          font: {
            size: 16,
          },
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Downside Risk %',
          font: {
            size: 16,
          },
        },
        ticks: {
          callback: function(value) {
            return value.toFixed(2);
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: ''
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.raw.toFixed(1) + '%';
            return label;
          }
        }
      }
    }
  };

  const chartData = {
    labels: data.map(point => point.year),
    datasets: [
      {
        label: 'DrawDown',
        data: data.map(point => point.drawdown * 100),
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false,
        tension: 0.3
      }
    ]
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <ExportButton chartId="drawdownChart" />
      </Box>
      <Box className={classes.chartContainer}>
        <div id="drawdownChart" style={{ height: '100%', width: '100%' }}>
          <Line
            data={chartData}
            options={options}
            ref={chartRef}
          />
        </div>
      </Box>
    </Box>
  );
};

export default DrawDown;