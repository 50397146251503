export const getColorForAssetType = (assetType) => {
  switch(assetType) {
    case 'Equity':
      return "#008ed1";
    case 'Bond':
      return "#00d0af";
    case 'Real Estate':
      return "#9786ec";
    case 'Multi Asset':
      return "#414bdf";
    case 'Commodity':
      return "#80c6e8";
    case 'Gold':
      return "#80e7d7";
    default:
      return "#80e7d7";  // Default color for unknown asset types
  }
};

export const assetTypeColors = {
  Equity: "red",
  "Real Estate": "cyan",
  Bond: "blue",
  "Multi Asset": "green",
  Commodity: "black",
  Gold: "yellow",
  Unknown: "gray",
};
