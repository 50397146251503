import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import  {axiosInstance}  from '../../axiosConfig';
import { useSelector } from 'react-redux';
import "../../App.css";
import { ExportButton } from './StrategyContent';
import { Box, Grid } from '@mui/material';

function escapeString(str) {
    return str.replace(/['[\]&]/g, '\\$&');
}

function convertToNewickString(node, assets) {
    const ticker = node.name || '';
    const distance = node.distance || 0;

    const matchedAsset = assets.find(asset => asset.ticker === ticker);
    const description = matchedAsset ? escapeString(matchedAsset.description) : '';

    const annotations = description ? `[&description='${description}']` : '';
    const label = `${ticker}${annotations}`;

    if (!node.children || node.children.length === 0) {
        return `${label}:${distance}`;
    }

    const childrenStrings = node.children.map(child => convertToNewickString(child, assets)).join(',');
    let nodeString = `(${childrenStrings})${label}`;

    if (node.distance !== undefined) {
        nodeString += `:${node.distance}`;
    }

    return nodeString;
}

const PhylogeneticTree = ({ strategyId }) => {
    const svgRef = useRef(null);
    const [isReady, setIsReady] = useState(false);
    const [newickTree, setNewickTree] = useState(null);
    const [tooltip, setTooltip] = useState({ show: false, content: '', x: 0, y: 0 });

    const assets = useSelector(state => state.strategy.assets);
    const settings = useSelector(state => state.strategy.settings);
    const startYear = new Date(settings.startDate).getFullYear() || '2013'
    const endYear = new Date(settings.endDate).getFullYear() || '2020'

    useEffect(() => {
        const fetchTreeData = async () => {
            try {
                const response = await axiosInstance.get(
                    `/strategies/${strategyId}/cladogram/?end_year=${endYear}&start_year=${startYear}`
                );

                const cladogram = response.data.cladogram;
                setNewickTree(cladogram);
                setIsReady(true);
            } catch (error) {
                console.error('Error fetching tree data:', error);
            }
        };

        fetchTreeData();
    }, [strategyId, settings, startYear, endYear]);

    useEffect(() => {
        const loadFigTree = async () => {
            if (!isReady || !svgRef.current || !newickTree) return;

            try {
                const figtreeModule = await import('https://rambaut.github.io/figtree.js/dist/figtree.esm.js');
                const { Tree, FigTree, rectangularLayout, branch, circle, tipLabel, internalNodeLabel } = figtreeModule;

                const newickString = convertToNewickString(newickTree, assets) + ';';

                const tree = Tree.parseNewick(newickString);
                tree.annotateNode(tree.root, { root: true });

                const margins = { top: 10, bottom: 60, left: 100, right: 150 };
                const branchSettings = branch().hilightOnHover().reRootOnClick().curve(d3.curveStepBefore);

                const figTree = new FigTree(svgRef.current, margins, tree)
                    .layout(rectangularLayout)
                    .nodes(
                        circle().attr("r", 5).hilightOnHover(10).rotateOnClick(),
                        tipLabel(d => d.name),
                        internalNodeLabel(d => d.label)
                    )
                    .nodeBackgrounds(circle().attr("r", 7))
                    .branches(branchSettings);

                figTree.update();

                d3.select(svgRef.current)
                    .selectAll('g.node')
                    .on('mouseover', function (event) {
                        const node = d3.select(this).datum();
                        const asset = assets.find(asset => asset.ticker === node.name);

                        if (!asset || !asset.description) {
                            return;
                        }


                        setTooltip({
                            show: true,
                            content: `Description: ${asset.description}`,
                            x: event.clientX + 1,
                            y: event.clientY + 1
                        });
                    })
                    .on('mouseout', function () {
                        setTooltip({ show: false });
                    });
            } catch (error) {
                console.error('Error loading or initializing FigTree:', error);
            }
        };

        loadFigTree();
    }, [isReady, newickTree, assets]);


    return (
        <Box className="phylogenetic-tree-container" sx={{ position: 'relative' }} id="phylogramDownload">
            <Grid container sx={{ justifyContent: 'flex-end' }}>
                <ExportButton chartId="phylogramDownload" />
            </Grid>
            <svg ref={svgRef} id="phylogram_1a" className='cladogram-wrapper'></svg>
            {tooltip.show && (
                <div
                    style={{
                        position: 'absolute',
                        left: `${tooltip.x}px`,
                        top: `${tooltip.y / 2.5}px`,
                        background: '#1999d6',
                        color: 'white',
                        borderRadius: '5px',
                        padding: '5px',
                        fontSize: '12pt',
                        pointerEvents: 'none',
                        zIndex: 1000,
                    }}
                >
                    {tooltip.content}
                </div>
            )}
            <style>{`
          .phylogenetic-tree-container {
            font-family: "helvetica-neue", "helvetica", "sans-serif";
            position: relative;
          }

          .controls {
            margin-top: 10px;
          }
          button {
            margin-right: 10px;
            padding: 5px 10px;
            font-size: 14px;
          }
        `}</style>
        </Box>
    );
};

export default PhylogeneticTree;
