import React, {  useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  Grid,
  Divider,
  useMediaQuery,
  IconButton,
  CardMedia,
  Card,
} from "@mui/material";
import {  useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Close as CloseIcon,
} from "@mui/icons-material";
import ReactPlayer from "react-player/vimeo";
import rectangle from "../images/rectangle-1.svg";
import hero from "../images/hero.png";
import videoHome from "../images/video.png";
import "../App.css";
import { useDispatch } from "react-redux";
import { clearDrawer, openStrategyDrawer } from "../features/drawerSlice";


const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openVideo, setOpenVideo] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenVideo = () => setOpenVideo(true);
  const handleCloseVideo = () => setOpenVideo(false);

  const onHandleStrategies = () => {
    navigate("/strategies");
  };


  const onCreateStrategy = () => {
    dispatch(clearDrawer());
    dispatch(openStrategyDrawer());
  };

  return (
    <>
      <Grid container flexDirection="column">
        <Box
          lg={12}
          className="customCard intersect"
          sx={{
            height: { xs: "100vh", sm: "65vh", md: "62vh" },
            // maybe to remove this for mobile
            background: `
            url(${hero}) no-repeat,
            url(${rectangle}) no-repeat
            `,
            backgroundSize: {
              xs: "95%, 50%",
              sm: "60%, 45%",
              md: "45%, 100%",
            },
            backgroundPosition: {
              xs: "center 99%",
              sm: "right, bottom",
              md: "right, 74% 100%",
            },
          }}
        >
          <Grid item xs={12} md={6} p={1}>

            <p />
            <Typography
              variant="homeDescription"
              gutterBottom
              sx={{ color: "#ffff" }}
            >
              These tools are designed to help you see how various investment strategies would have performed historically.
              The goal is to calibrate your expectations about the future so you have a very rough idea what the future return and risk of a strategy looks like given the past return and risk.
              A strategy is a combination of (a) a set of investments and their associated allocation weightings and (b) a rebalancing strategy that determines what to do once varying return moves your investments away from the weights you specify.
              We have provided some famous strategies but also allowed you to define your own.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
                marginTop: "4rem",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Button
                variant="contained"
                onClick={onHandleStrategies}
                style={{
                  textTransform: "capitalize",
                  fontSize: "18px",
                  backgroundColor: "#1D2155",
                  color: "#fff",
                  padding: "15px 55px",
                  borderRadius: "2px",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Browse Strategies
              </Button>
              <Button
                variant="outlined"
                onClick={onCreateStrategy}
                style={{
                  textTransform: "capitalize",
                  fontSize: "18px",
                  backgroundColor: "#fff",
                  color: "#008ED1",
                  padding: "15px 55px",
                  borderRadius: "2px",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Create Your Strategy
              </Button>
            </Box>
          </Grid>
        </Box>

        <Grid item p={3} lg={2}>
          <Box sx={{ display: "inline-block" }}>
            <Typography
              variant="homeHead"
              style={{ fontSize: "30px", fontWeight: 600 }}
              gutterBottom
            >
              Get Started
            </Typography>
            <Divider
              sx={{
                borderBottomWidth: 6,
                width: "100px",
                borderColor: "#00D0AF",
                marginTop: "1px",
                opacity: 1,
              }}
            />
          </Box>
        </Grid>

        <Box sx={{ flexGrow: 1, backgroundColor: "#f3f4f6", padding: "3%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ height: "auto" }}>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                }}
              >
                <Card sx={{ width: "100%", borderRadius: "0%" }}>
                  <CardMedia
                    component="img"
                    sx={{ width: "100%", height: "auto" }}
                    image={videoHome}
                    title="video"
                    onClick={handleOpenVideo}
                  />
                </Card>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{ height: isMobile ? "unset" : "40vh" }}
            >
              <Grid sx={{ height: "100%" }}>
                <Grid item md={12} sx={{ padding: "4%" }}>
                  <Typography
                    variant="homeHead"
                    sx={{ color: "#008ED1", fontWeight: 600 }}
                  >
                    Get Started
                  </Typography>
                  <Typography
                    variant="homeDescription"
                    component="div"
                    sx={{ marginTop: "2rem", marginBottom: "2rem" }}
                  >
                    The best way to learn about the app is to try it out! The easiest way to do this is to go to “Strategies” in the left hand navigation menu, then select one of the “Famous Strategies” such as “Pinwheel Portfolio”.
                    Then click on the “eye” in the bottom right panel for that strategy and click on the “Calculate” button. You’ll now see the historic risk and return for that strategy.
                    To learn more about each of the graphs and what they mean go to the “Learn” section in the left hand navigation menu.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#008ED1",
                      padding: "15px 40px",
                      borderRadius: "2px",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                    onClick={handleOpenVideo}
                  >
                    Watch Video
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          component="footer"
          sx={{
            mt: "auto",
            py: 2,
          }}
        >
          <Typography variant="body2" align={isMobile ? "center" : "left"} color="text.secondary">
            Copyright © PensionCraft Ltd. All Rights Reserved.
          </Typography>
        </Box>
      </Grid>
      <Modal
        open={openVideo}
        onClose={handleCloseVideo}
        aria-labelledby="video-modal"
        aria-describedby="fullscreen-video"
      >
        <Box
          className="video-container"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            height: '80vh',
            bgcolor: 'background.paper',
            background: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseVideo}
            sx={{
              position: "absolute",
              top: 8,
              right: 138,
              color: 'white',
              background: 'black'
            }}
          >
            <CloseIcon />
          </IconButton>
          <ReactPlayer
            url="https://vimeo.com/1018941841/50ef14a44a"
            width="100%"
            height="100%"
            playing
            controls={true}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Home;
