import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Button,
  Select,
  InputAdornment,
  FormControlLabel,
  Switch,
  useMediaQuery,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  setYearRange,
  setSettings,
  setCalculated,
} from "../../features/strategySlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { memo, useCallback, useEffect, useState } from "react";
import theme from "../../theme";

const StrategySettings = memo(
  ({
    settingsExpanded,
    setSettingsExpanded,
    strategyId,
    assetStartYear,
    assetEndYear,
  }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const globalSettings = useSelector((state) => state.strategy.settings);
    const [investmentError, setInvestmentError] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [localSettings, setLocalSettings] = useState({
        startDate: new Date(`${assetStartYear}-01-01`),
    endDate: new Date(`${assetEndYear}-12-31`),
      investmentAmount: "",
      rebalancingPeriod: 0,
      transactionFeeValue: "",
      transactionFeeType: "-",
    });

    useEffect(() => {
        setLocalSettings({
          investmentAmount: globalSettings.investmentAmount || "",
          rebalancingPeriod: globalSettings.rebalancingPeriod || 0,
          transactionFeeValue: globalSettings.transactionFeeValue || "",
          transactionFeeType:
            globalSettings.rebalancingPeriod === 0
              ? "-"
              : globalSettings.transactionFeeType || "-",
              startDate: new Date(globalSettings.startDate || `${assetStartYear}-01-01`),
              endDate: new Date(globalSettings.endDate || `${assetEndYear}-12-31`),
        });
      }, [assetEndYear, assetStartYear, globalSettings]);

    const handleSettingsToggle = useCallback(() => {
      setSettingsExpanded(false);
    }, [setSettingsExpanded]);

    const isValidDate = (date) => {
        return date instanceof Date && !isNaN(date);
      };

    const handleDateChange = (field) => (newValue) => {
    if (!isValidDate(newValue)) {
      console.error(`Invalid date for ${field}:`, newValue);
      return;
    }

    const minDate = new Date(`${assetStartYear}-01-01`);
    const maxDate = new Date(`${assetEndYear}-12-31`);

    if (newValue < minDate || newValue > maxDate) {
      console.error(`Date out of range for ${field}:`, newValue);
      return;
    }

    setLocalSettings(prev => ({ ...prev, [field]: newValue }));

    // Update global settings
    const updatedSettings = {
      ...globalSettings,
      [field]: newValue.toISOString().split('T')[0],
    };
    dispatch(setSettings(updatedSettings));
  };



    const handleInputChange = (field) => (e) => {
        const value = e.target.value;

        setLocalSettings((prev) => {
          let updatedSettings = { ...prev, [field]: value };

          if (field === "investmentAmount") {
            const investmentAmount = parseFloat(value);
            if (investmentAmount <= 0 || isNaN(investmentAmount)) {
              setInvestmentError("Initial investment must be greater than 0");
            } else {
              setInvestmentError("");
            }
          }

          if (field === "rebalancingPeriod") {
            const rebalancingPeriod = parseInt(value, 10);
            if (rebalancingPeriod === 0 || isNaN(rebalancingPeriod)) {
              updatedSettings.transactionFeeType = "-";
              updatedSettings.transactionFeeValue = "";
            }
          }

          if (field === "transactionFeeType") {
            if (value === "-") {
              updatedSettings.transactionFeeValue = "";
            } else if (!updatedSettings.transactionFeeValue) {
              updatedSettings.transactionFeeValue = "1";
            }
          }

          return updatedSettings;
        });
      };

    const handleRecalculate = () => {
        const investmentAmount = parseFloat(localSettings.investmentAmount);
        if (investmentAmount <= 0) {
          setInvestmentError("Initial investment must be greater than 0");
          return;
        } else {
          setInvestmentError("");
        }

        const startYear = localSettings.startDate.getFullYear();
        const endYear = localSettings.endDate.getFullYear();

        const rebalancingFrequency = localSettings.rebalancingPeriod;

        const params = {
          id: strategyId,
          start_year: startYear,
          end_year: endYear,
          rebalancing_frequency_in_years: rebalancingFrequency,
          investment_amount: investmentAmount,
        };

        if (rebalancingFrequency !== 0 && localSettings.transactionFeeType !== "-") {
          let transactionFeeValue = parseFloat(localSettings.transactionFeeValue || "0");
          if (localSettings.transactionFeeType === "%") {
            transactionFeeValue /= 100;
            params.transaction_fee_type = "percentage";
          } else {
            params.transaction_fee_type = "pounds";
          }
          params.transaction_fee_amount = transactionFeeValue;
        }

        dispatch(
          setSettings({
            ...localSettings,
            startDate: localSettings.startDate.toISOString().split("T")[0],
            endDate: localSettings.endDate.toISOString().split("T")[0],
          })
        );

        dispatch(setYearRange([startYear, endYear]));
        dispatch(setCalculated(true));

        setSettingsExpanded(false);
      };

      const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenSnackbar(false);
      };

      useEffect(() => {
        setOpenSnackbar(true);


      }, []);

    return (
      settingsExpanded && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid
            container
            direction="column"
            spacing={3}
            sx={{ margin: "0 auto", width: "80%" }}
          >
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item>
                <IconButton
                  sx={{
                    padding: "14px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                  onClick={handleSettingsToggle}
                >
                  <CloseIcon sx={{ color: "#008ed1" }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                sx={{ color: "#1D2155", fontWeight: 600 }}
              >
                Simulation Period
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat , sans-serif",
                  fontSize: "14px",
                  color: "#1D2155",
                  fontWeight: "400",
                }}
              >
                currency simulation
                <span style={{ background: "#fff", fontWeight: 600 }}>
                  {" "}
                  £(GBP)
                </span>
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat , sans-serif",
                    fontSize: "16px",
                    color: "#1D2155",
                    fontWeight: "600",
                  }}
                >
                  Start Date
                </Typography>

                <DatePicker
                  views={["year"]}
                  openTo="year"
                  minDate={new Date(`${assetStartYear}-01-01`)}
                  maxDate={new Date(`${assetEndYear}-12-31`)}
                  slotProps={{
                    popper: {
                      sx: {
                        "& .MuiPickersYear-yearButton": {
                          lineHeight: "1.3em"
                        },
                      },
                    },
                  }}
                  sx={{
                    width: "90%",
                    "& .MuiInputBase-root": {
                      background: "white",
                    },
                    "& .MuiButtonBase-root": {
                      color: "#008ED1",
                    },
                    "& .MuiPickersYear-yearButton": {
                        lineHeight: "2em", // Adjust the line height here
                      },
                  }}
                  value={localSettings.startDate}
                  onChange={handleDateChange("startDate")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat , sans-serif",
                    fontSize: "16px",
                    color: "#1D2155",
                    fontWeight: "600",
                  }}
                >
                  End Date
                </Typography>
                <DatePicker
                  views={["year"]}
                  openTo="year"
                  minDate={new Date(`${assetStartYear}-01-01`)}
                  maxDate={new Date(`${assetEndYear}-12-31`)}
                  sx={{
                    width: "90%",
                    "& .MuiInputBase-root": {
                      background: "white",
                    },
                    "& .MuiButtonBase-root": {
                      color: "#008ED1",
                    },
                  }}
                  slotProps={{
                    popper: {
                      sx: {
                        "& .MuiPickersYear-yearButton": {
                          lineHeight: "1.3em"
                        },
                      },
                    },
                  }}
                  value={localSettings.endDate}
                  onChange={handleDateChange("endDate")}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={2.5}
                xl={2}
                sx={{ display: "flex", paddingTop: "34px!important" }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={localSettings.rebalancingPeriod !== 0}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setLocalSettings((prev) => ({
                          ...prev,
                          rebalancingPeriod: isChecked ? 1 : 0,
                          transactionFeeType: isChecked
                            ? prev.transactionFeeType
                            : "-",
                          transactionFeeValue: isChecked
                            ? prev.transactionFeeValue
                            : "",
                        }));
                      }}
                    />
                  }
                  label="Rebalancing"
                />
              </Grid>

              <Grid item xs={12} md={2} sx={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat , sans-serif",
                      fontSize: "16px",
                      color: "#1D2155",
                      fontWeight: "600",
                    }}
                  >
                    Years
                  </Typography>

                  <TextField
                    type="number"
                    inputProps={{ min: 1, max: 10 }}
                    value={localSettings.rebalancingPeriod}
                    onChange={handleInputChange("rebalancingPeriod")}
                    sx={{ background: "white" }}
                    disabled={localSettings.rebalancingPeriod === 0}
                    fullWidth
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                sx={{ color: "#1D2155", fontWeight: 600 }}
              >
                Extra
              </Typography>
            </Grid>
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "16px",
                    color: "#1D2155",
                    fontWeight: "600",
                  }}
                >
                  Transaction Fee
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  sx={{ background: "white" }}
                  type="number"
                  value={localSettings.transactionFeeValue}
                  onChange={handleInputChange("transactionFeeValue")}
                  disabled={
                    localSettings.transactionFeeType === "-" ||
                    localSettings.rebalancingPeriod === 0
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          value={localSettings.transactionFeeType}
                          onChange={handleInputChange("transactionFeeType")}
                          disabled={localSettings.rebalancingPeriod === 0}
                          variant="standard"
                          sx={{ width: "60px" }}
                        >
                          <MenuItem value="-">-</MenuItem>
                          <MenuItem value="£">£</MenuItem>
                          <MenuItem value="%">%</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {localSettings.transactionFeeType}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat , sans serif",
                    fontSize: "16px",
                    color: "#1D2155",
                    fontWeight: "500",
                  }}
                >
                  per transaction
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat , sans-serif",
                    fontSize: "16px",
                    color: "#1D2155",
                    fontWeight: "600",
                  }}
                >
                  Initial investment <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box position="relative">
                  <TextField
                    sx={{
                      background: "white",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: investmentError
                            ? "red"
                            : "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: investmentError
                            ? "red"
                            : "rgba(0, 0, 0, 0.23)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: investmentError ? "red" : "#1976d2",
                        },
                      },
                    }}
                    type="number"
                    //   label="Investment Amount"
                    error={!!investmentError}
                    value={localSettings.investmentAmount}
                    onChange={handleInputChange("investmentAmount")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                    required
                    fullWidth
                  />
                </Box>
                {investmentError && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "red",
                      mt: 1,
                      display: "block",
                    }}
                  >
                    {investmentError}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container justifyContent="flex-start" pt={2} pb={3}>
              <Grid
                item
                mr={isMobile ? 0 : 2}
                mb={isMobile ? 2 : 0}
                xs={12}
                lg={2}
              >
                <Button
                  variant="contained"
                  sx={{
                    background: "#008ed1",
                    color: "#fff",
                    width: "100%",
                    padding: isMobile ? "12px 20px" : "16px 48px",
                    textTransform: "capitalize",
                    borderRadius: "0px",
                  }}
                  onClick={handleRecalculate}
                >
                  Calculate
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  variant="outlined"
                  sx={{
                    background: "#fff",
                    color: "#008ed1",
                    width: "100%",
                    padding: isMobile ? "12px 20px" : "16px 48px",
                    textTransform: "capitalize",
                    borderRadius: "0px",
                  }}
                  onClick={handleSettingsToggle}
                >
                  Cancel
                </Button>
                <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          sx={{ width: "100%", backgroundColor: "#008ed1", color: "white" }}

        >
          You should calculate first to display the correct data on graphs
        </Alert>
      </Snackbar>
              </Grid>
            </Grid>
          </Grid>
        </LocalizationProvider>
      )
    );
  }
);

export default StrategySettings;
