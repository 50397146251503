import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  CardContent,
  Divider,
  Card,
  CardMedia,
  Modal,
  Box,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactPlayer from 'react-player/vimeo';
import video2 from '../images/Drawdown.jpg';
import video3 from '../images/Saving.jpg';
import video4 from '../images/Cladogram.jpg';
import video5 from '../images/riskreturnplot.jpg';
import video6 from '../images/withdroal.jpg';
import video7 from '../images/performance.jpg';
import stock100 from '../images/famouscharts/100stock.png';
import global6040 from '../images/famouscharts/global.png';
import goldenbut from '../images/famouscharts/goldenbuf.png';
import pinwheel from '../images/famouscharts/pinwheel.png';
import weirdPortfolio from '../images/famouscharts/weird.png';
import permanent from '../images/famouscharts/permanent.png';


import '../App.css';
import theme from '../theme';

const resources = [
{
    title: "Performance",
    description: "Given your chosen portfolio and rebalancing frequency we calculate the return you would have received historically. This is an annualised return, and it assumes that any income is reinvested and inflation is subtracted from the return. Returns are in sterling and the rate of inflation used is UK CPI inflation. A positive rate of return means that your portfolio beats inflation by that amount each year.",
    image: video7,
    videoUrl: "https://vimeo.com/1018952448/3e12f506fa",
  },
  {
    title: "Downside Risk",
    description: "This is a measure of the crashiness of a portfolio. It shows the percentage fall of your portfolio’s value below its all-time-high up to that point in time. It is best to have a portfolio that seldom crashes and which has shallow falls from which it recovers quickly. Diversifying a portfolio usually reduces the frequency and depth of these crashes.",
    image: video2,
    videoUrl: "https://vimeo.com/1018952528/013572dc48",
  },
  {
    title: "Risk and Return",
    description: "This displays the historical return and risk, as measured by volatility, of both your portfolio and the funds contained in the portfolio. Ideally you would have a low volatility and high return. Investment is a tradeoff between the highest return possible for a given level of risk. By diversifying your portfolio you can reduce the volatility of your portfolio without sacrificing much return.",
    image: video5,
    videoUrl: "https://vimeo.com/1018952589/c5d2f101a3",
  },

  {
    title: "Cladogram",
    description: "With large portfolios it becomes difficult to visualise how correlated funds are in the portfolio. The idea of a cladogram is it shows which funds have the most strongly correlated returns, as these occur close together in the tree. You should probably avoid having highly correlated funds in your portfolio as this increases the overall volatility of your portfolio. Incorporating funds spaced far apart on the tree that are less correlated ensures they act as better diversifiers of one another and reduces your portfolio risk.",
    image: video4,
    videoUrl: "https://vimeo.com/1018953434/14ede1c914",
  },
  {
    title: "Accumulation/savings",
    description: "This tool is intended to answer the question of how long it will take to save a certain amount of money. This is uncertain because investment returns are uncertain. The tool simulates many possible but plausible scenarios and comes up with a probability of meeting your target based on the portfolio you choose and the other starting assumptions you enter about your initial investment, the rate of inflation and how much you save each year and the rate at which those savings grow. The more you save the more likely you are to meet your target.",
    image: video3,
    videoUrl: "https://vimeo.com/1018964876/859b0121c3",
  },


  {
    title: "Withdrawal",
    description: "This tool is intended to answer the question of how long your savings will last in retirement and whether you will run out of money before you die. This is uncertain because investment returns are uncertain. The tool simulates many possible but plausible scenarios and comes up with a probability of not running out of money based on the portfolio you choose and the other starting assumptions you enter about your initial withdrawal rate, the rate of inflation. The more money you start with and the lower the withdraw.",
    image: video6,
    videoUrl: "https://vimeo.com/1018967915/b93c02ce92",
  },
];

const famousStrategies = [
  {
    title: "100% Global Stocks Portfolio",
    description: "A global market-cap weighted stock portfolio.",
    image: stock100,
    videoUrl: "https://vimeo.com/1018973316/0c09ca6509",
  },
  {
    title: "60/40 Global Portfolio",
    description: "The classic stock/bond portfolio in the ratio of 60% stocks to 40% bonds.",
    image: global6040,
    videoUrl: "https://vimeo.com/1018973286/132777bcba",
  },
  {
    title: "Golden Butterfly Portfolio",
    description: " A portfolio designed by Tyler of PortfolioCharts inspired by the Permanent Portfolio that equally balances prosperity, recession, inflation, and deflation. The Golden Butterfly tilts the assets towards prosperity with an additional allocation to small cap value.",
    image: goldenbut,
    videoUrl: "https://vimeo.com/1018973353/5bac220eb9",
  },
  {
    title: "Pinwheel Portfolio",
    description: "This portfolio contains domestic stocks, international stocks, intermediate bonds, and real estate but adds one complementary asset of each type to increase return but also to diversify further.",
    image: pinwheel,
    videoUrl: "https://vimeo.com/1018973437/59320a4b22",
  },
  {
    title: "Weird Portfolio",
    description: "This portfolio is created by Value Stock Geek and uses small caps to boost equity return while at the same time diversifying across safe assets and real assets.",
    image: weirdPortfolio,
    videoUrl: "https://vimeo.com/1018973490/69fcd1a804",
  },
  {
    title: "Permanent Portfolio",
    description: "Harry Browne’s portfolio was designed to have one asset that performed well in almost any economic environment with stocks performing well during periods of prosperity, cash holding up during recessions, gold providing a hedge against inflation and long term Treasuries retaining their value during periods of deflation.",
    image: permanent,
    videoUrl: "https://vimeo.com/1018973389/e462522066",
  }
];

const Learn = () => {
  const [openVideo, setOpenVideo] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));



  const handleOpenVideo = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setOpenVideo(true);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  useEffect(() => {
    // Preload ReactPlayer by rendering a hidden instance
    setOpenVideo(true);
    setTimeout(() => setOpenVideo(false), 0);
  }, []);



  return (
    <>
      <Grid container flexDirection="column" pt={2} pb={2}>
        <Grid item p={isMobile ? 1 : 3} lg={3} pl={isMobile ? 2 : 6}>
          <Typography variant="head" gutterBottom >
            Learn
          </Typography>
        </Grid>

        <hr />

        <Grid container justifyContent="center" p={2} sx={{ width: '100%' }}>
          <Grid item xs={12} sm={6} md={11.5}>
            <Box sx={{ display: 'inline-block', paddingBottom: '10px' }}>
              <Typography variant="body3" style={{ fontSize: '30px', fontWeight: 600 }} gutterBottom>
                Tools
              </Typography>
              <Divider sx={{ borderBottomWidth: 6, width: '100px', borderColor: '#00D0AF', marginTop: '1px', opacity: 1 }} />
            </Box>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <Grid container spacing={3} justifyContent="center" sx={{ gap: '32px' }}>
            {resources.map((resource, index) => (
              <Grid item xs={12} sm={6} md={3.6} key={index} >
                <Card
                  sx={{
                    height: '100%',
                    width: '100%',
                    minHeight: '684px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#f3f4f6',
                  }}
                  elevation={0}
                >
                  <Box p={2}>
                    <CardMedia
                      component="img"
                      image={resource.image}
                      alt={resource.title}
                      onClick={() => handleOpenVideo(resource.videoUrl)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </Box>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      sx={{ color: '#008ED1', fontSize: 22, fontFamily: "'Quicksand', sans-serif", fontWeight: 600 }}
                      gutterBottom
                      variant="body1"
                      component="div"
                    >
                      {resource.title}
                    </Typography>
                    <Typography variant="learnDescription">
                      {resource.description}
                    </Typography>
                  </CardContent>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ margin: 2, padding: '16px 48px', background: '#008ED1', borderRadius:'0%', textTransform: 'capitalize' }}
                      onClick={() => handleOpenVideo(resource.videoUrl)}
                    >
                      Watch Video
                    </Button>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Grid container justifyContent="center" p={2} mt={3} sx={{ width: '100%' }}>
          <Grid item xs={12} sm={6} md={11.5}>
            <Box sx={{ display: 'inline-block', paddingBottom: '10px' }}>
              <Typography variant="body3" style={{ fontSize: '30px', fontWeight: 600 }} gutterBottom>
                Strategies
              </Typography>
              <Divider sx={{ borderBottomWidth: 6, width: '100px', borderColor: '#00D0AF', marginTop: '1px', opacity: 1 }} />
            </Box>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <Grid container spacing={3} justifyContent="center" sx={{ gap: '32px' }}>
            {famousStrategies.map((resource, index) => (
              <Grid item xs={12} sm={6} md={3.6} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#f3f4f6',
                  }}
                  elevation={0}
                >
                  <Box p={2}>
                    <CardMedia
                      component="img"
                      image={resource.image}
                      alt={resource.title}
                      sx={{ cursor: 'pointer' }}
                    />
                  </Box>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      sx={{ color: '#008ED1', fontSize: 22, fontFamily: "'Quicksand', sans-serif", fontWeight: 600 }}
                      gutterBottom
                      variant="body1"
                      component="div"
                    >
                      {resource.title}
                    </Typography>
                    <Typography variant="learnDescription">
                      {resource.description}
                    </Typography>
                  </CardContent>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ margin: 2, padding: '16px 48px', background: '#008ED1', borderRadius:'0%', textTransform: 'capitalize' }}
                      onClick={() => handleOpenVideo(resource.videoUrl)}
                    >
                      Watch Video
                    </Button>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Grid container justifyContent="center" p={2} sx={{ width: '100%' }}>
          <Grid item xs={12} sm={6} md={10.5}>
            <Typography variant="body2" align={isMobile ? "center" : "left"} color="text.secondary">
              Copyright © PensionCraft Ltd. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={openVideo}
        onClose={handleCloseVideo}
        aria-labelledby="video-modal"
        aria-describedby="fullscreen-video"
      >
        <Box
          className="vimeo-container"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            height: '80vh',
            bgcolor: 'background.paper',
            background: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseVideo}
            sx={{
              position: 'absolute',
              top: 8,
              right: 138,
              color: 'white',
              background: 'black'
            }}
          >
            <CloseIcon />
          </IconButton>
          <ReactPlayer
            url={currentVideoUrl}
            width="100%"
            height="100%"
            playing={openVideo}
            controls={true}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Learn;
