import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { closeInfoDrawer } from "../features/drawerSlice";
import drawerImage from "../images/drawerinfo.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "white",
    height: "12vh",
    position: "relative",
    padding: "10px",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: -20,
      left: 0,
      right: 0,
      height: "20px",
      background: `
            linear-gradient(to top right, transparent 48%, white 50%) left,
            linear-gradient(to top left, transparent 48%, white 50%) right
          `,
      backgroundSize: "50% 100%",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "5px",
      "&::after": {
        bottom: -10,
        height: "10px",
      },
    },
  },
  drawer: {
    "& .MuiPaper-root": {
      backgroundColor: "#eff0f6",
      width: "650px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  drawerContent: {
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
  title: {
    fontSize: "30px",
    color: "#1D2155",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  image: {
    width: "90%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const InfoDrawer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = useSelector((state) => state.drawer.infoDrawerOpen);
  const content = useSelector((state) => state.drawer.content);

  const handleClose = () => {
    dispatch(closeInfoDrawer());
  };

  const renderContent = () => {
    switch (content) {
      case "performance":
        return (
          <>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              className={classes.header}
            >
              <Grid item xs={3}>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    padding: "12px",
                    borderRadius: "50%",
                    background: "#EFF0F6",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={9}>
                <Box sx={{ display: "inline-block" }}>
                  <Typography
                    variant="body3"
                    className={classes.title}
                    gutterBottom
                  >
                    Performance Calculation
                  </Typography>
                  <Divider
                    sx={{
                      borderBottomWidth: 6,
                      width: "60%",
                      borderColor: "#00D0AF",
                      marginTop: "1px",
                      opacity: 1,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.drawerContent}
              sx={{ gap: "20px", paddingTop: "45px" }}
            >
              <img
                className={classes.image}
                src={drawerImage}
                alt="drawerImage"
              />

              <Typography variant="body1">
                Given your chosen portfolio and rebalancing frequency we
                calculate the return you would have received historically. This
                is an annualised return, and it assumes that any income is
                reinvested and inflation is subtracted from the return. Returns
                are in sterling and the rate of inflation used is UK CPI
                inflation. A positive rate of return means that your portfolio
                beats inflation by that amount each year.
              </Typography>
            </Grid>
          </>
        );
      case "drawdown":
        return (
          <>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              className={classes.header}
            >
              <Grid item xs={3}>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    padding: "12px",
                    borderRadius: "50%",
                    background: "#EFF0F6",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={9}>
                <Box sx={{ display: "inline-block" }}>
                  <Typography
                    variant="body3"
                    className={classes.title}
                    gutterBottom
                  >
                    Downside Risk Calculation
                  </Typography>
                  <Divider
                    sx={{
                      borderBottomWidth: 6,
                      width: "60%",
                      borderColor: "#00D0AF",
                      marginTop: "1px",
                      opacity: 1,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.drawerContent}
              sx={{ gap: "30px", paddingTop: "45px" }}
            >
              <img
                className={classes.image}
                src={drawerImage}
                alt="drawerImage"
              />

              <Typography variant="body1">
                This is a measure of the crashiness of a portfolio. It shows the
                percentage fall of your portfolio’s value below its
                all-time-high up to that point in time. It is best to have a
                portfolio that seldom crashes and which has shallow falls from
                which it recovers quickly. Diversifying a portfolio usually
                reduces the frequency and depth of these crashes.
              </Typography>
            </Grid>
          </>
        );
      case "Risk & Return":
        return (
          <>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              className={classes.header}
            >
              <Grid item xs={3}>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    padding: "12px",
                    borderRadius: "50%",
                    background: "#EFF0F6",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={9}>
                <Box sx={{ display: "inline-block" }}>
                  <Typography
                    variant="body3"
                    className={classes.title}
                    gutterBottom
                  >
                    Risk & Return Calculation
                  </Typography>
                  <Divider
                    sx={{
                      borderBottomWidth: 6,
                      width: "60%",
                      borderColor: "#00D0AF",
                      marginTop: "1px",
                      opacity: 1,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.drawerContent}
              sx={{ gap: "30px", paddingTop: "45px" }}
            >
              <img
                className={classes.image}
                src={drawerImage}
                alt="drawerImage"
              />

              <Typography variant="body1">
                This displays the historical return and risk, as measured by
                volatility, of both your portfolio and the funds contained in
                the portfolio. Ideally you would have a low volatility and high
                return. Investment is a tradeoff between the highest return
                possible for a given level of risk. By diversifying your
                portfolio you can reduce the volatility of your portfolio
                without sacrificing much return.
              </Typography>
            </Grid>
          </>
        );
      case "cladogram":
        return (
          <>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              className={classes.header}
            >
              <Grid item xs={3}>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    padding: "12px",
                    borderRadius: "50%",
                    background: "#EFF0F6",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={9}>
                <Box sx={{ display: "inline-block" }}>
                  <Typography
                    variant="body3"
                    className={classes.title}
                    gutterBottom
                  >
                    Cladogram Calculation
                  </Typography>
                  <Divider
                    sx={{
                      borderBottomWidth: 6,
                      width: "60%",
                      borderColor: "#00D0AF",
                      marginTop: "1px",
                      opacity: 1,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.drawerContent}
              sx={{ gap: "30px", paddingTop: "45px" }}
            >
              <img
                className={classes.image}
                src={drawerImage}
                alt="drawerImage"
              />

              <Typography variant="body1">
                With large portfolios it becomes difficult to visualise how
                correlated funds are in the portfolio. The idea of a cladogram
                is it shows which funds have the most strongly correlated
                returns, as these occur close together in the tree. You should
                probably avoid having highly correlated funds in your portfolio
                as this increases the overall volatility of your portfolio.
                Incorporating funds spaced far apart on the tree that are less
                correlated ensures they act as better diversifiers of one
                another and reduces your portfolio risk.
              </Typography>
            </Grid>
          </>
        );
      case "monteCarlo":
        return (
          <>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              className={classes.header}
            >
              <Grid item xs={3}>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    padding: "12px",
                    borderRadius: "50%",
                    background: "#EFF0F6",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={9}>
                <Box sx={{ display: "inline-block" }}>
                  <Typography
                    variant="body3"
                    className={classes.title}
                    gutterBottom
                  >
                    MonteCarlo Calculation
                  </Typography>
                  <Divider
                    sx={{
                      borderBottomWidth: 6,
                      width: "60%",
                      borderColor: "#00D0AF",
                      marginTop: "1px",
                      opacity: 1,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.drawerContent}
              sx={{ gap: "20px", paddingTop: "45px" }}
            >
              <img
                className={classes.image}
                src={drawerImage}
                alt="drawerImage"
              />
              <Typography variant="h5">Monte Carlo saving</Typography>
              <Typography variant="body1" sx={{marginBottom: "20px"}}>
                This tool is intended to answer the question of how long it will
                take to save a certain amount of money. This is uncertain
                because investment returns are uncertain. The tool simulates
                many possible but plausible scenarios and comes up with a
                probability of meeting your target based on the portfolio you
                choose and the other starting assumptions you enter about your
                initial investment, the rate of inflation and how much you save
                each year and the rate at which those savings grow. The more you
                save the more likely you are to meet your target.
              </Typography>
              <br />
              <Typography variant="h5">Monte Carlo drawdown</Typography>
              <Typography variant="body1">
                This tool is intended to answer the question of how long your
                savings will last in retirement and whether you will run out of
                money before you die. This is uncertain because investment
                returns are uncertain. The tool simulates many possible but
                plausible scenarios and comes up with a probability of not
                running out of money based on the portfolio you choose and the
                other starting assumptions you enter about your initial
                withdrawal rate, the rate of inflation. The more money you start
                with and the lower the withdrawal rate the more likely you are
                not to run out of money.
              </Typography>
            </Grid>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <Box>{renderContent()}</Box>
    </Drawer>
  );
};

export default InfoDrawer;
