import { createSlice } from '@reduxjs/toolkit';

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    strategyDrawerOpen: false,
    infoDrawerOpen: false,
    content: null,
    strategyData: null,
  },
  reducers: {
    openStrategyDrawer: (state) => {
      state.open = true;
    },
    closeStrategyDrawer: (state) => {
      state.open = false;
      state.strategyData = null;
    },
    openInfoDrawer: (state, action) => {
      state.infoDrawerOpen = true;
      state.content = action.payload;
    },
    closeInfoDrawer: (state) => {
      state.infoDrawerOpen = false;
      state.content = null;
    },
    setStrategyData(state, action) {
      state.strategyData = action.payload;
    },
    clearDrawer: (state) => {
      state.strategyData = null;
    },
  },
});

export const {
  openStrategyDrawer,
  closeStrategyDrawer,
  openInfoDrawer,
  closeInfoDrawer,
  setStrategyData,
  clearDrawer,
} = drawerSlice.actions;
export default drawerSlice.reducer;
