import axios from 'axios';
import Cookies from 'js-cookie';

let name = null;

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const cookieData = Cookies.get('PensionCraftUserData');
        let jwtToken = null;

        if (cookieData) {
            try {
                const parsedCookie = JSON.parse(cookieData);
                jwtToken = parsedCookie.jwt;
                name = parsedCookie.name;
                if (jwtToken) {
                    config.headers['Authorization'] = `Bearer ${jwtToken}`;
                } else {
                    console.error('JWT token not found in cookie');
                }
            } catch (error) {
                console.error('Error parsing cookie data:', error);
            }
        } else {
            console.error('Error reading cookie');
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);

    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            window.location.href = 'https://pensioncraft.com/investor-education/login/';
        }
        return Promise.reject(error);
    }
);

const fetchWithRetry = async (url, options = {}, retries = 3, delay = 1000) => {
    try {
        const response = await axiosInstance.get(url, options);
        return response.data;
    } catch (error) {
        const statusCode = error.response?.status;

        const shouldRetry = !error.response || statusCode >= 500 || error.code === 'ECONNABORTED';

        if (retries > 0 && shouldRetry) {
            await new Promise((resolve) => setTimeout(resolve, delay));
            return fetchWithRetry(url, options, retries - 1, delay * 2);
        } else {
            throw error;
        }
    }
};

export { axiosInstance, fetchWithRetry, name };
